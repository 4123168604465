<template>
	<section class="position-relative px-4 pb-3">
		<div class="d-flex justify-content-between align-items-center mb-4 mt-5">
			<h3 class="text-left text-secondary font-weight-bolder mr-2">
				Gerencie seus números de disparo
			</h3>
			<span v-b-modal.addPhoneModal role="button" v-if="$role(['phones.create']) && plan.used_phones < plan.max_phones">
				<font-awesome-icon icon="fa-solid fa-add" />
				Adicionar número de disparo
			</span>
		</div>

		<common-table-custom :loading="loading.phones" v-bind="phonesTable">
			<template v-slot:custom="{ data }">
				<div class="d-flex align-items-center justify-content-center">
					<span
						v-if="$role(['phones.update']) && data.status === 'disconnected'"
						v-b-modal.qrCodeModal
						class="mx-2 text-success d-flex align-items-center"
						role="button"
						@click="qrCodePhoneSelected = data._id"
						v-b-tooltip="'O número a ser conectado deve ser o mesmo cadastrado'"
					>
						<font-awesome-icon icon="fa-solid fa-qrcode" />
						<span class="ml-2">Conectar</span>
					</span>
					<span
						class="mx-2 text-secondary d-flex align-items-center"
						role="button"
						v-if="$role(['phones.update'])"
						@click="() => syncPhone(data._id)"
					>
						<font-awesome-icon
							:spin="loading.syncing.includes(data._id)"
							icon="fa-solid fa-rotate"
						/>
						<span class="ml-2">Sincronizar</span>
					</span>
					<span
						class="mx-2 text-secondary d-flex align-items-center"
						role="button"
                        v-b-modal.importContactsModal
						v-if="$role(['customers.import']) && data.status === 'connected' && !data.importing_batch"
						:disabled="data.importing_batch"
						@click="importContactsPhone = data"
					>
						<font-awesome-icon
							icon="fa-solid fa-download"
						/>
						<span class="ml-2">Importar</span>
					</span>
					<span
						v-else-if="$role(['customers.import']) && data.status === 'connected' && data.importing_batch"
						class="mx-2 text-secondary d-flex align-items-center"
						v-b-tooltip.hover.top="
							`Os contatos e/ou chats desse número estão sendo importados. Você será notificado quando essa ação for finalizada`
						"
					>
						<font-awesome-icon icon="fa-solid fa-download" />
						<span class="ml-2">Importando</span>
					</span>
					<span
						@click="transferPhone = data"
						class="mx-2 text-blue d-flex align-items-center"
						role="button"
						v-if="$role(['phones.update'])"
					>
						<font-awesome-icon icon="fa-solid fa-right-left" />
						<span class="ml-2">Transferir</span>
					</span>
					<span
						@click="openModalEdit(data)"
						class="mx-2 text-blue d-flex align-items-center"
						role="button"
						v-if="$role(['phones.update'])"
					>
						<font-awesome-icon icon="fa-solid fa-pencil" />
						<span class="ml-2">Editar</span>
					</span>
					<span
						v-if="$role(['phones.delete']) && data.canceled_at == null"
						@click="deletePhone(data)"
						class="mx-2 text-orange d-flex align-items-center"
						role="button"
					>
						<font-awesome-icon icon="fa-solid fa-ban" />
						<span class="ml-2">Remover</span>
					</span>
					<span
						v-else-if="data.canceled_at != null && data.status !== 'cancelled'"
						class="mx-2 text-warning d-flex align-items-center"
						v-b-tooltip="
							`Número em processo de cancelamento. Dias restantes: ${moment(
								data.canceled_at
							)
								.add(30, 'days')
								.subtract(moment())
								.format('DD')}`
						"
					>
						<font-awesome-icon icon="fa-solid fa-ban" />
						<span class="ml-2">Cancelando</span>
					</span>
					<span
						v-else-if="data.status === 'cancelled' || data.deleted_at"
						class="mx-2 text-danger d-flex align-items-center"
						v-b-tooltip="
							'Número cancelado, porém ainda existem números associados a ele.'
						"
					>
						<font-awesome-icon icon="fa-solid fa-ban" />
						<span class="ml-2">Cancelado</span>
					</span>
					<span
						v-if="(data.status === 'cancelled' || data.deleted_at) && data.contact_count === 0"
						class="mx-2 text-danger d-flex align-items-center"
						@click="deletePhone(data)"
						role="button"
						v-b-tooltip="
							'Ao excluir, esse número sairá da lista'
						"
					>
						<font-awesome-icon icon="fa-solid fa-ban" />
						<span class="ml-2">Excluir</span>
					</span>
				</div>
			</template>
		</common-table-custom>

		<qr-code-modal
			:phone-id="qrCodePhoneSelected"
			@connected="(id) => changeStatus(id, 'connected')"
			v-if="$role(['phones.update'])"
		/>
		<transfer-phone-number-modal @update="getPhones" v-model="transferPhone" />
		<add-phone-modal
			v-if="$role(['phones.create'])"
			@update="getPhones"
			@close="phone = null"
			:phone_edit="phone"
		/>
		<import-phone-contacts-modal
			@update="getPhones"
			@close="importContactsPhone = null"
			:phone="importContactsPhone"
			v-if="$role(['customers.import'])"
		/>
	</section>
</template>

<script>
import { phonesTableModel } from "@/views/pages/dashboard/settings/constants.js";
import TransferPhoneNumberModal from "@/views/pages/dashboard/settings/components/TransferPhoneNumberModal.vue";
import QrCodeModal from "@/views/pages/dashboard/settings/components/QrCodeModal.vue";
import CommonTableCustom from "@/views/components/CommonTableCustom.vue";
import ImportPhoneContactsModal from "@/views/pages/dashboard/settings/components/ImportPhoneContactsModal.vue";
import api from "@/api";
import AddPhoneModal from "./components/AddPhoneModal.vue";
import moment from "moment";

export default {
	components: {
		CommonTableCustom,
		TransferPhoneNumberModal,
		QrCodeModal,
		AddPhoneModal,
		ImportPhoneContactsModal
	},
	data() {
		return {
			loading: {
				phones: false,
				syncing: []
			},
			phonesTable: phonesTableModel,
			qrCodePhoneSelected: null,
			transferPhone: null,
			importContactsPhone: null,
			phone: null
		};
	},
	computed: {
		plan() {
			return this.$store.getters["user/getPlan"];
		}
	},
	watch: {
		transferPhone: {
			handler(val) {
				if (val == null) {
					return this.$root.$bvModal.hide("transferPhoneNumberModal");
				}
				return this.$root.$bvModal.show("transferPhoneNumberModal");
			},
			deep: true
		}
	},
	created() {
		this.getPhones();
	},
	methods: {
		moment,
		openModalEdit(phone) {
			this.phone = phone;
			return this.$root.$bvModal.show("addPhoneModal");
		},
		toast(message, type = "danger") {
			this.$bvToast.toast(message, {
				title: "Telefones",
				autoHideDelay: 2000,
				variant: type === "error" ? "danger" : type
			});
		},
		getPhones() {
			this.loading.phones = true;
			api.get("/phones")
				.then((response) => {
					const { type, body, message } = response.data;

					if (type === "success") return (this.phonesTable.items = body);

					throw message;
				})
				.catch((err) => {
					console.log(err);
					this.phonesTable.items = [];
				})
				.finally(() => {
					this.loading.phones = false;
					this.phone = null;
				});
		},
		deletePhone(phone) {
			this.$swal({
				title: "Tem certeza que deseja remover o telefone?",
				html: `Digite <b>${phone.phone}</b> abaixo para confirmar:`,
				inputText: "efsefse",
				inputAttributes: {
					autocapitalize: "off"
				},
				input: "text",
				confirmButtonText: "Remover",
				cancelButtonText: "Cancelar",
				showCancelButton: true,
				reverseButtons: true,
				showLoaderOnConfirm: true,
				preConfirm: (text) => {
					if (text !== phone.phone)
						return this.$swal.showValidationMessage(`Confirmação inválida`);

					return api.delete(`/phones/${phone._id}`);
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			})
				.then((result) => {
					if (result.isConfirmed) {
						const { message, type } = result.value.data;

						if (type === "success") this.getPhones();

						this.toast(message, type);
					}
				})
				.catch(() => {
					this.toast("Não foi possível remover telefone", "danger");
				});
		},
		syncPhone(id) {
			if (this.loading.syncing.findIndex((e) => e === id) >= 0) return;

			this.loading.syncing.push(id);
			api.patch(`/phones/${id}`)
				.then((response) => {
					const { type, body, message } = response.data;

					if (type === "success") {
						this.$bvToast.toast("Telefone sincronizado com sucesso.", {
							title: "Sincronização",
							variant: "success"
						});
						const i = this.phonesTable.items.findIndex((e) => e._id === id);
						if (i >= 0) Object.assign(this.phonesTable.items[i], body);
						return;
					}

					throw message;
				})
				.catch((err) => {
					console.log(err);
					this.$bvToast.toast("Não foi possível sincronizar o telefone.", {
						title: "Sincronização",
						variant: "danger"
					});
				})
				.finally(() => {
					const index = this.loading.syncing.findIndex((e) => e === id);
					if (index >= 0) this.loading.syncing.splice(index, 1);
				});
		},
		changeStatus(id, status) {
			const index = this.phonesTable.items.findIndex((e) => e._id === id);
			if (index >= 0) Object.assign(this.phonesTable.items[index], { status });
		}
	}
};
</script>
