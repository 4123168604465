<script>
import api from "@/api";
import CommonTableCustom from "@/views/components/CommonTableCustom.vue";
import {usersTableModel, phonesTableModel} from "@/views/pages/dashboard/settings/constants.js";

export default {
    data() {
        return {
            usersTable: usersTableModel,
            phonesTable: phonesTableModel,
            loading: {
                users: true,
                phones: true
            },
            selectedUsers: [],
            selectedPhones: []
        }
    },
    components: {
        CommonTableCustom,
    },
    computed: {
        plan() {
            return this.$store.getters['user/getPlan'];
        },
    },
    methods: {
        fetchUsers() {
            api.get('/users')
                .then(response => {
                    const {type, body} = response.data;
                    if (type === 'success') {
                        this.usersTable.items = body;
                    }
                })
                .finally(() => this.loading.users = false);
        },
        fetchPhones() {
            api.get('/phones')
                .then(response => {
                    const {type, body} = response.data;
                    if (type === 'success') {
                        this.phonesTable.items = body;
                    }
                })
                .finally(() => this.loading.phones = false);
        },
        /**
         * Remove o usuário.
         * @param {Object} user
         * @param {String} user._id
         */
        async asyncDeleteUser(user) {
            try {
                const response = await api.delete(`/users/${user._id}`);

                const {type, message} = response.data;
                if (type === 'success') {
                    this.usersTable.items = this.usersTable.items.filter(v => v._id != user._id);
                    this.selectedUsers = this.selectedUsers.filter(v => v._id != user._id);
                    return this.toast(`${user.name} removido com sucesso.`, 'success');
                }
                this.toast(message);
            } catch (error) {
                if (error.response) {
                    return this.toast(error.response.data.message);
                }
                this.toast("Não foi possível remover o usuário, tente novamente mais tarde.")
                console.log(error);
            }
        },
        handleDeleteUsers() {
            this.loading.users = true;

            Promise.all(this.selectedUsers.map(async user => this.asyncDeleteUser(user)))
                .finally(() => {
                    this.loading.users = false
                    this.$store.dispatch('user/loadSession');
                });
        },
        /**
         * Remove o telefone.
         * @param {Object} phone
         * @param {String} phone._id
         */
        async asyncDeletePhone(phone) {
            try {
                const response = await api.delete(`/phones/${phone._id}`);

                const {type, message} = response.data;
                if (type === 'success') {
                    this.phonesTable.items = this.phonesTable.items.filter(v => v._id != phone._id);
                    this.selectedPhones = this.selectedPhones.filter(v => v._id != phone._id);
                    return this.toast(`${phone.phone} removido com sucesso.`, 'success');
                }
                this.toast(message);
            } catch (error) {
                if (error.response) {
                    return this.toast(error.response.data.message);
                }
                this.toast("Não foi possível remover o telefone, tente novamente mais tarde.")
                console.log(error);
            }
        },
        handleDeletePhones() {
            this.loading.phones = true;

            Promise.all(this.selectedPhones.map(async phone => this.asyncDeletePhone(phone)))
                .finally(() => {
                    this.loading.phones = false
                    this.$store.dispatch('user/loadSession');
                });
        },
    },
    mounted() {
        this.fetchUsers();
        this.fetchPhones();
    }
}
</script>

<template>
    <section class="position-relative px-4 pb-3">
        <section class="my-5">
            <h3 class="text-blue">Remover excessos do plano</h3>
            <p class="text-muted">Você está usando mais recursos do que o seu plano permite.</p>
            <h3 class="text-muted mb-3">Plano selecionado: {{ this.user.plan.name }}</h3>
            <div v-if="plan.used_agents > plan.max_agents">
                <h4 class="text-muted mb-3">Você possui {{ plan.used_agents }} agentes e seu plano permite {{ plan.max_agents }}.</h4>
                <common-table-custom :loading="loading.users" v-bind="usersTable" v-if="!loading.users">
                    <template v-slot:custom="item">
                        <input v-model="selectedUsers" :value="item.data" name="selectedUsers" type="checkbox"/>
                    </template>
                </common-table-custom>
            </div>
            <div v-if="plan.used_phones > plan.max_phones">
                <h4 class="text-muted mb-3">Você possui {{ plan.used_phones }} telefones e seu plano permite {{ plan.max_phones }}.</h4>
                <common-table-custom :loading="loading.phones" v-bind="phonesTable" v-if="!loading.phones">
                    <template v-slot:custom="item">
                        <input v-model="selectedPhones" :value="item.data" name="selectedPhones" type="checkbox"/>
                    </template>
                </common-table-custom>
            </div>
        </section>
        <div v-if="selectedUsers.length > 0"
                       class="bg-primary d-flex flex-column flex-sm-row justify-content-between align-items-center px-4">
            <p class="m-0 text-light font-weight-regular fs-4">{{ selectedUsers.length }} pessoa selecionada</p>
            <div>
                <font-awesome-icon
                    v-if="$role(['users.delete'])"
                    :hidden="loading.users"
                    class="my-1 ml-4 text-light"
                    icon="fa-solid fa-trash-can"
                    role="button"
                    size="lg"
                    style="outline: none"
                    @click="handleDeleteUsers"
                />
            </div>
        </div>
        <div v-if="selectedPhones.length > 0"
                       class="bg-primary d-flex flex-column flex-sm-row justify-content-between align-items-center px-4">
            <p class="m-0 text-light font-weight-regular fs-4">{{ selectedPhones.length }} telefone selecionado</p>
            <div>
                <font-awesome-icon
                    v-if="$role(['phones.delete'])"
                    :hidden="loading.phones"
                    class="my-1 ml-4 text-light"
                    icon="fa-solid fa-trash-can"
                    role="button"
                    size="lg"
                    style="outline: none"
                    @click="handleDeletePhones"
                />
            </div>
        </div>
    </section>
</template>
