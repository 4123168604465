<template>
	<b-modal
		:no-close-on-backdrop="loading"
		id="changePlanModal"
		@show="getPlans"
		centered
		title="Mudar plano"
		body-class="position-unset"
		content-class="p-4 position-relative"
		footer-border-variant="light"
		header-border-variant="light"
		hide-footer
		size="xl"
		lazy
	>
		<plan-carousel
			:plans="plans"
			:loading="loading"
			@chosen="(plan) => submit(plan._id)"
			:selected-plan-id="user.plan_id ?? undefined"
			:selected-plan-type="user.plan_type ?? undefined"
		/>
	</b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import PlanCarousel from "@/views/components/PlanCarousel.vue";

import api from "@/api";

export default {
	components: {
		BModal,
		PlanCarousel
	},
	data() {
		return {
			loading: false,
			plans: []
		};
	},
	computed: {
		user() {
			return this.$store.getters["user/get"];
		},
		selectedPlanId() {
			if ('next_plan_id' in this.user)
				return this.user.next_plan_id;

			return this.user.plan_id;
		}
	},
	mounted() {
		console.log(this.user)
	},
	methods: {
		toast(message, type = "danger") {
			this.$bvToast.toast(message, {
				title: "Planos",
				autoHideDelay: 2000,
				variant: type
			});
		},
		submit(planId) {
			this.$swal({
				title: "Alterar plano",
				text: "Tem certeza que deseja mudar de plano? As alterações só serão aplicadas após o término do plano atual, e a próxima fatura virá com o valor integral do novo plano. Você só pode fazer isso uma vez por faturamento.",
				confirmButtonText: "Sim",
				cancelButtonText: "Cancelar",
				showCancelButton: true,
				reverseButtons: true
			}).then((result) => {
				if (result.isConfirmed) {
					this.loading = true;
					api.post("/me/plan", { plan_id: planId })
						.then(() => {
							this.$swal({
								title: "Plano alterado",
								text: "O plano foi alterado com sucesso. A próxima fatura virá com o valor integral do novo plano.",
								confirmButtonText: "Ok",
								icon: "success"
							}).then(() => {
								window.location.reload();
							});
						})
						.finally(() => {
							this.loading = false;
						});
				}
			});
		},
		getPlans() {
			if (this.plans.length > 0) return;

			this.loading = true;

			api.get("/plans")
				.then((res) => {
					const { body, type } = res.data;

					if (type === "success") {
						return (this.plans = body);
					}

					throw res;
				})
				.catch((err) => {
					let message = "Não foi possível carregar planos.";

					if (err.response) message = err.response.data.message;

					this.toast(message);
				})
				.finally(() => (this.loading = false));
		}
	}
};
</script>

<style lang="scss" scoped>
.card-list {
	overflow-x: auto;

	.card {
		min-width: 200px;

		.card-body {
			min-width: 200px;
		}

		ul {
			margin-left: -20px;
		}
	}
}
</style>
