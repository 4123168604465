<template>
    <section class="d-flex justify-content-center px-4 pb-3">
        <div v-if="loading">
            <loading/>
        </div>

        <section class="row mt-4 d-flex justify-content-center">
            <div class="col-12 alert alert-info text-center px-4 pb-3">
                <div>
                    Parece que seu email ainda não está verificado.
                </div>
                <div>
                    Não recebeu o email de verificação? <b class="font-weight-bold" style="cursor:pointer" @click="resendVerificationEmail">CLIQUE AQUI</b>
                </div>
            </div>

            <otp
                :digit-count="5"
                :endpoint="'/users/validateEmail'"
                @update:otp="otpValue=$event"
            >

            </otp>
        </section>
    </section>
</template>
<script>
import api from '@/api'
import Loading from "@/views/components/Loading.vue";
import otp from "./components/OTP.vue"

export default {
    components: {
        Loading,
        otp
    },
    data() {
        return {
            loading: false
        }
    },
    // computed: {
    //     user_id: this.user._id
    // },
    methods: {
        resendVerificationEmail() {
            const params = {
                user_id: this.user._id
            }
            // console.log(params)
            this.loading = true
            api.post('/users/resendEmail', {...params},
            )
                .then((res) => {
                    this.$bvToast.toast('Email de confirmação enviado com sucesso!', {title: 'Sucesso!', variant: 'info'})
                    console.log(res)
                })
                .catch((error) => {
                    console.log(error)
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            // api.post('/users/test', formData).then(() => {
            //     this.loading = false
            // })
        }
    }
}
</script>
