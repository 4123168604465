<template>
    <b-modal id="addUserModal" v-model="visible" :no-close-on-backdrop="loading.status" centered
             title="Adicionar Pessoa">
        <loading v-if="loading.status"/>
        <validation-observer ref="addUserForm">
            <form>
                <div class="form-group">
                    <validation-provider #default="{ errors }" name="nome" rules="required">
                        <label for="add-user-name">Nome</label>
                        <input id="add-user-name" v-model="newUser.name" :class="`form-control ${errors.length > 0 && 'is-invalid'}`"
                               required type="text"/>
                        <small class="invalid-feedback">{{ errors[0] }}</small>
                    </validation-provider>
                </div>
                <div class="form-group">
                    <validation-provider #default="{ errors }" name="email" rules="required|email">
                        <label for="add-user-email">Email</label>
                        <input id="add-user-email" v-model="newUser.email" :class="`form-control ${errors.length > 0 && 'is-invalid'}`"
                               required type="email">
                        <small class="invalid-feedback">{{ errors[0] }}</small>
                    </validation-provider>
                </div>
                <div class="form-group">
                    <label for="add-user-group">Equipe</label>
                    <validation-provider #default="{ errors }" name="equipe" rules="required">
                        <input :value="newUser.group_id" hidden type="text">
                        <v-select v-model="newUser.group_id" :class="`flex-grow-1 ${errors.length > 0 && 'is-invalid'}`"
                                  :clearable="false"
                                  :get-option-label="(option) => option.name" :loading="loading.groups"
                                  :options="groups" :reduce="(option) => option._id" :selectable="(option) => !groups.includes(option._id)"
                                  placeholder="Selecione uma Equipe">
                            <span slot="no-options">Desculpe, não há opções correspondentes.</span>
                        </v-select>
                        <small class="invalid-feedback">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- <select v-model="newUser.group_id" required class="form-control" id="add-user-group">
                        <option selected hidden>Selecione uma Equipe</option>
                    </select> -->
                </div>
            </form>
        </validation-observer>
        <template #modal-footer="{ cancel }">
            <div class="d-flex align-items-center jusitfy-content-end">
                <button :disabled="loading.status" class="btn btn-secondary mx-2" @click="cancel">Cancelar</button>
                <button :disabled="loading.status" class="btn btn-primary" type="submit"
                        @click="handleAddUser()">Adicionar
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {required, email} from "@validations";
import vSelect from "vue-select";
import api from "@/api";

export default {
    components: {
        BModal,
        Loading,
        vSelect,
        ValidationProvider,
        ValidationObserver
    },
    emits: ['update'],
    data() {
        return {
            newUser: {
                name: null,
                group_id: null,
                email: null,
            },
            groups: [],
            loading: {
                status: false,
                groups: false
            },
            visible: false,
            required,
            email
        }
    },
    created() {
        this.fetchGroups()
    },
    methods: {
        toast(message, type = "danger") {
            this.$bvToast.toast(message, {
                title: 'Usuários',
                autoHideDelay: 2000,
                variant: type
            })
        },
        resetForm() {
            this.newUser = {
                name: null,
                group_id: null,
                email: null,
            };
            this.$refs.addUserForm.reset();
        },
        fetchGroups() {
            this.loading.groups = true;
            api
                .get('/groups', {params: {only: ['_id', 'name'], all: 1}})
                .then(response => {
                    const {body, type} = response.data;
                    if (type === 'success') {
                        this.groups = body;
                    }
                })
                .finally(() => this.loading.groups = false);
        },
        handleAddUser() {
            this.$refs.addUserForm.validate()
                .then(result => {
                    if (!result) return;

                    this.loading.status = true;
                    api
                        .post('/users', this.newUser)
                        .then(response => {
                            const {type, message, errors, body} = response.data

                            if (type === 'success') {
                                this.$emit('update');
                                this.resetForm();
                                return this.$swal({
                                    title: 'Usuário criado com sucesso',
                                    confirmButtonText: 'Ok',
                                    confirmButtonColor: "#317f98",
                                    html: `
                                        Credenciais para o primeiro acesso:<br>
                                        <b>Email: </b> ${body.email}<br>
                                        <b>Senha: </b> ${body.password}<br>
                                    `,
                                })
                            }

                            if (errors.length > 0) {
                                const error = errors.find(v => String(v).includes('email'));
                                if (error) return this.$refs.addUserForm.setErrors({'email': [error]});

                            }
                            this.toast(message);
                        })
                        .catch(error => {
                            if (error.response) {
                                return this.toast(error.response.data.message);
                            }
                            this.toast("Não foi possível adicionar usuário, tente novamente em alguns minutos.")
                        })
                        .finally(() => this.loading.status = false);
                })

        }
    },
    watch: {
        visible(value) {
            if (!value) this.resetForm();
        }
    }
}
</script>

<style lang="scss">
.is-invalid .vs__dropdown-toggle {
    border-color: red !important;
}
</style>
