<template>
    <b-modal :no-close-on-backdrop="loading" id="updatePasswordModal" centered title="Atualizar Senha">
        <loading v-if="loading"/>
        <validation-observer ref="passwordValidation">
            <form @submit.prevent="handleUpdatePassword">
                <div class="form-group">
                    <validation-provider
                        #default="{ errors }"
                        name="senha atual"
                        rules="required"
                    >
                        <label for="add-group-password">Senha Atual</label>
                        <input v-model="newPassword.old" required type="password" :class="`form-control ${errors.length > 0 ? 'is-invalid': ''}`" id="add-group-password">
                        <small class="text-danger">
                            {{ errors[0] }}
                        </small>
                    </validation-provider>
                </div>
                <div class="form-group">
                    <validation-provider
                        #default="{ errors }"
                        name="nova senha"
                        rules="required|min:8"
                    >
                        <label for="add-group-new-password">Nova Senha</label>
                        <input v-model="newPassword.new" required type="password" :class="`form-control ${errors.length > 0 ? 'is-invalid': ''}`" id="add-group-new-password">
                        <small class="text-danger">
                            {{ errors[0] }}
                        </small>
                    </validation-provider>
                </div>
                <div class="form-group">
                    <validation-provider
                        #default="{ errors }"
                        name="confirmar nova senha"
                        rules="required|confirmed:nova senha"
                    >
                        <label for="add-group-confirm-new-password">Confirmar Nova Senha</label>
                        <input v-model="newPassword.new_confirmation" required type="password" :class="`form-control ${errors.length > 0 ? 'is-invalid': ''}`" id="add-group-confirm-new-password">
                        <small class="text-danger">
                            {{ errors[0] }}
                        </small>
                    </validation-provider>
                </div>
            </form>
        </validation-observer>
        <template #modal-footer="{ cancel, close }">
            <div class="d-flex align-items-center jusitfy-content-end">
                <button :disabled="loading" @click="cancel" class="btn btn-secondary mx-2">Cancelar</button>
                <button :disabled="loading" @click.prevent="handleUpdatePassword(close)" class="btn btn-primary">Atualizar</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { BModal } from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import api from "@/api";

export default {
    components: {
        BModal,
        Loading,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            newPassword: {
                old: null,
                new: null,
                new_confirmation: null
            },
            loading: false,
            required
        }
    },
    methods: {
        handleUpdatePassword(handleCloseModal) {
            this.$refs.passwordValidation.validate().then(success => {
                if(!success) return;
                this.loading = true;

                api.put('/me', this.newPassword)
                .then(response => {
                    const { type, errors, message, body } = response.data;

                    if(type == 'success') {
                        this.$swal('Senha atualizada', 'A senha foi atualizada com sucesso!','success');
                        this.$store.commit('user/SET_TOKEN', body.token);
                        return handleCloseModal()
                    }
                    this.$swal(message, errors.length > 0 ? errors[0] : '','error');
                })
                .catch(error => {
                    if(error.response) {
                        const { message, errors } = error.response.data;
                        return this.$swal(message, errors.length > 0 ? errors[0] : '','error');
                    }
                    this.$swal('Erro ao atualizar senha', 'Não foi possível atualizar a senha, tente novamente mais tarde.');
                })
                .finally(() => this.loading = false);
            });
        }
    }
}
</script>
