import { currencyFormatter } from "@/api/constants";
import { formatPhone } from "@/api/functions";
import moment from "moment";

// User.vue Open
export const groupsTableModel = {
	fields: [
		{
			key: "name",
			label: "Equipes",
			sortable: true,
			sortDirection: "asc",
			class: "text-left",
			thClass: "col-6 col-md-4"
		},
		{
			key: "members_count",
			label: "Membros",
			sortable: true,
			class: "text-left"
		},
		{
			key: "custom",
			label: "Ações"
		}
	]
};

export const usersFiltersModel = {
	search: null,
	order: null
};

export const usersTableModel = {
	fields: [
		{
			key: "name",
			label: "Pessoas",
			sortable: true,
			sortDirection: "asc",
			class: "text-left",
			thClass: "col-6 col-md-4"
		},
		{
			key: "group.name",
			label: "Equipe",
			sortable: true,
			class: "text-left"
		},
		{
			key: "custom",
			label: "Selecionar"
		}
	]
};
// Users.vue Close

// Permissions.vue Open
export const preDefinedPermissions = {
	supervisor: [
		"forms.get",
		"customers.get",
		"marks.create",
		"marks.update",
		"marks.delete",
		"reports.get",
		"chats.get",
		"chats.update",
		"chats.block"
	],
	crisis: [
		"chats.get",
		"chats.update",
		"chats.block",
		"campaigns.get",
		"campaigns.create",
		"campaigns.update",
		"campaigns.delete"
	],
	manager: [
		"forms.get",
		"forms.create",
		"forms.update",
		"forms.delete",
		"customers.get",
		"customers.import",
		"customers.create",
		"customers.update",
		"customers.delete",
		"rules.get",
		"rules.create",
		"rules.update",
		"rules.delete",
		"marks.create",
		"marks.update",
		"marks.delete",
		"campaigns.get",
		"campaigns.create",
		"campaigns.update",
		"campaigns.delete",
		"reports.get",
		"reports.create",
		"answers.get",
		"answers.create",
		"answers.update",
		"answers.delete",
		"phones.get",
		"phones.create",
		"phones.update",
		"phones.delete"
	],
	attendance: ["chats.get", "chats.update", "marks.create", "marks.update"]
};
// Permissions.vue Close

// Hall.vue Open
export const hallTableModel = {
	fields: [
		{
			key: "name",
			label: "Regras",
			sortable: false,
			sortDirection: "asc",
			class: "text-left",
			thClass: "col-6 col-md-4"
		},
		{
			key: "members_count",
			label: "Membros",
			sortable: false
		},
		{
			key: "contacts_count",
			sortable: false,
			label: "Contatos"
		},
		{
			key: "custom",
			label: ""
		}
	],
	items: []
};
// Hall.vue Close

// PhoneNumbers.vue Open
export const phonesTableModel = {
	fields: [
		{
			key: "name",
			label: "Nome",
			sortable: false,
			class: "text-left text-nowrap",
			thClass: "col-6 col-md-3"
		},
		{
			key: "phone",
			label: "Número",
			sortable: false,
			class: "text-left text-nowrap",
			thClass: "col-6 col-md-2",
			formatter: (value) => {
				try {
					return formatPhone(value);
				} catch (e) {
					return value;
				}
			}
		},
		{
			key: "contact_count",
			label: "Contatos",
			thClass: "col-6 col-md-1",
			sortable: false
		},
		{
			key: "status",
			label: "Status",
			sortable: false,
			thClass: "col-6 col-md-2",
			formatter: (value) => {
				const types = {
					connected: "Conectado",
					disconnected: "Desconectado",
					expired: "Expirado",
					cancelled: "Cancelado"
				};
				if (Object.keys(types).includes(value)) return types[value];
				return "Desconectado";
			}
		},
		{
			key: "custom",
			sortable: false,
			thClass: "col",
			label: ""
		}
	],
	items: []
};
// PhoneNumbers.vue Close

// Plans.vue Open
export const plansTableModel = {
	fields: [
		{
			key: "created_at",
			label: "Data",
			sortable: false,
			class: "text-left col-1",
			formatter: (val) => moment(val).format('DD/MM/YYYY')
		},
		{
			key: "plan_name",
			label: "Plano",
			sortable: false,
			class: "text-left col-4",
			formatter: (val, _, item) => `${val} (${item.type_name})`
		},
		{
			key: "payment_form_name",
			label: "Forma de Pagamento",
			class: "col-2",
			sortable: false
		},
		{
			key: "value",
			label: "Valor",
			sortable: false,
			class: "col-2",
			formatter: (val, _, item) => ['manual', 'trial'].includes(item.payment_form) ? '-' : currencyFormatter.format(val)
		},
		{
			key: "status_name",
			sortable: false,
			label: "Status",
			class: "col-2",
		},
		{
			key: "paid_at",
			sortable: false,
			label: "Pago em",
			class: "col-1",
			formatter: (val) => val ? moment(val).format('DD/MM/YYYY') : '-'
		}
	],
	items: [],
	filters: {
		order_column: null,
		order: null,
		search: null,
		limit: 5
	}
};
// Plans.vue Close
