<template>
	<section class="w-100">
		<div
			class="d-flex justify-content-center pill-group"
			v-if="planTypes && planTypes.length > 1"
		>
			<button
				class="btn text-white rounded-0"
				v-for="(k, i) in planTypes"
				:key="`pill-button-${k}`"
				:class="{
					'rounded-end': planTypes.length - 1 == i,
					'rounded-start': i == 0,
					'btn-primary': k == activeType,
					'btn-secondary': k != activeType
				}"
				@click="activeType = k"
			>
				{{ String(types[k] ?? "Desconhecido").toUpperCase() }}
			</button>
		</div>

		<carousel
			v-if="loading && plans.length == 0"
			ref="carousel"
			:perPageCustom="[
				[0, 1],
				[992, 2],
				[1200, 4]
			]"
			navigation-enabled
			:pagination-enabled="false"
		>
			<slide
				v-for="i in [1, 2, 3, 4]"
				:key="`plan-skeleton-${i}`"
				class="py-3 px-1"
				style="height: 320px"
			>
				<b-skeleton-img class="rounded" no-aspect height="320px"></b-skeleton-img>
			</slide>
		</carousel>

		<carousel
			v-else-if="plans.length > 1"
			ref="carousel"
			:perPageCustom="[
				[0, 1],
				[992, 2],
				[1200, 4]
			]"
			navigation-enabled
			:pagination-enabled="false"
		>
			<slide
				v-for="(plan, i) in processedPlans[activeType]"
				:key="`plan-card-${i}`"
				class="py-3"
				style="height: 320px"
			>
				<div
					class="card border flex-grow-1 mx-1 h-100"
					style="box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1) !important"
				>
					<div
						class="card-body d-flex flex-column justify-content-between align-items-center px-4 pt-5 pb-4"
					>
						<div>
							<h5 class="card-title text-blue text-center">
								{{ plan.name.toUpperCase() }}
							</h5>

							<span v-if="plan.discount > 0.5" class="d-flex align-items-center">
								<s class="mr-1">R${{ formatter.format(plan.original) }}</s>
								<span class="badge badge-primary">
									Economia {{ percentFormatter.format(plan.discount) }}%
								</span>
							</span>

							<b class="d-flex justify-content-center align-items-end mb-3">
								<span class="font-weight-normal">R$</span>
								<h2 class="font-weight-bold m-0 mx-1">
									{{ formatter.format(plan.value) }}
								</h2>
								<span class="font-weight-normal">/mês</span>
							</b>

							<ul class="plan-list flex-grow-1 my-2">
								<li v-if="plan.max_agents">
									{{ integerFormatter.format(plan.max_agents) }}
									{{ plan.max_agents == 1 ? "agente" : "agentes" }}
								</li>
								<li v-if="plan.max_phones">
									{{ integerFormatter.format(plan.max_phones) }}
									{{ plan.max_phones == 1 ? "número" : "números" }}
									de disparo
								</li>
								<li v-if="plan.max_customers">
									Até
									{{ integerFormatter.format(plan.max_customers) }}
									{{ plan.max_customers == 1 ? "contato" : "contatos" }}
								</li>
								<li v-else>Sem limite de contatos</li>
								<li v-if="!plan.can_shot">Apenas atendimento</li>
								<li v-else-if="plan.max_shots">
									Até
									{{ integerFormatter.format(plan.max_shots) }}
									{{ plan.max_shots == 1 ? "disparo" : "disparos" }}
									por mês
								</li>
								<li v-else>Sem limite de disparos</li>
							</ul>
						</div>

						<button
							:class="`btn ${
								isSelectedPlan(plan._id, activeType) ? 'btn-secondary' : 'btn-primary'
							} w-100`"
							:disabled="isSelectedPlan(plan._id, activeType) || loading"
							@click="$emit('chosen', plan, activeType)"
						>
							{{ isSelectedPlan(plan._id, activeType) ? "Plano atual" : planText }}
						</button>
					</div>
				</div>
			</slide>
		</carousel>
	</section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import { BSkeletonImg } from "bootstrap-vue";
import { planTypes } from "@/api/constants";

export default {
	components: {
		Carousel,
		Slide,
		BSkeletonImg
	},
	emits: ["chosen"],
	props: {
		plans: {
			type: Array,
			required: false,
			default: () => []
		},
		loading: {
			type: Boolean,
			default: () => false
		},
		selectedPlanId: {
			type: String,
			required: false
		},
		selectedPlanType: {
			type: String,
			required: false
		},
		planText: {
			type: String,
			default: () => "Escolher"
		},
	},
	data() {
		return {
			activeType: "monthly",
			formatter: Intl.NumberFormat("pt-BR", {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			}),
			percentFormatter: Intl.NumberFormat("pt-BR", {
				maximumFractionDigits: 2
			}),
			integerFormatter: Intl.NumberFormat("pt-BR", {
				maximumFractionDigits: 0
			}),
			types: planTypes
		};
	},
	mounted() {
		if (this.selectedPlanType && this.selectedPlanType in this.planTypes) {
			this.activeType = this.selectedPlanType;
		}

		setTimeout(this.$refs.carousel.computeCarouselWidth, 300);
	},
	computed: {
		processedPlans: function () {
			let avaiableOptions = {};

			if (!this.plans) return avaiableOptions;

			for (let i = 0; i < this.plans.length; i++) {
				const plan = this.plans[i];

				if (!("prices" in plan) || !("monthly" in plan.prices)) {
					continue;
				}

				const basePrice = Number(plan.prices.monthly ?? 0);

				if (basePrice < 0 || (basePrice == 0 && !plan.is_trial)) {
					continue;
				}

				const keys = Object.keys(plan.prices);

				for (let j = 0; j < keys.length; j++) {
					const type = keys[j];
					const price = plan.prices[type];

					if (price == null || price < 0 || (price == 0 && !plan.is_trial)) {
						continue;
					}

					const v = Number(price);
					const discount = (1 - v / basePrice) * 100;

					const params = {
						_id: plan._id,
						name: plan.name,
						discount: Math.round(discount * 100) / 100,
						original: Math.round(basePrice * 100) / 100,
						value: v,
						max_agents: plan.max_agents ?? null,
						max_phones: plan.max_phones ?? null,
						max_customers: plan.max_customers ?? null,
						max_shots: plan.max_shots ?? null,
						can_shot: plan.can_shot ?? true,
						is_trial: plan.is_trial ?? false
					};

					if (type in avaiableOptions) {
						avaiableOptions[type].push(params);
					} else {
						avaiableOptions[type] = [params];
					}
				}
			}

			const types = Object.keys(avaiableOptions);

			for (let i = 0; i < types.length; i++) {
				const type = types[i];
				let array = avaiableOptions[type];

				array.sort((a, b) => a.value - b.value);
				avaiableOptions[type] = array;
			}

			return avaiableOptions;
		},
		planTypes: function () {
			return Object.keys(this.processedPlans ?? {});
		}
	},
	methods: {
		isSelectedPlan(id, type) {
			if (!this.selectedPlanType || !this.selectedPlanId) return false;

			return this.selectedPlanType == type && this.selectedPlanId == id;
		}
	}
};
</script>

<style lang="scss">
.plan-list {
	list-style: none;
	padding: 0;
	font-size: 0.85rem;

	li:before {
		content: "✓";
		font-weight: bold;
		margin-right: 8px;
	}
}

.pill-group {
	> .rounded-start {
		border-bottom-left-radius: 200px !important;
		border-top-left-radius: 200px !important;
	}

	> .rounded-end {
		border-bottom-right-radius: 200px !important;
		border-top-right-radius: 200px !important;
	}

	@media screen and (max-width: 600px) {
		.btn {
			padding: 10px 12px !important;
			font-size: 0.6rem !important;
		}
	}

	@media screen and (min-width: 600px) {
		.btn {
			padding: 12px 16px;
			font-size: 0.9rem;
		}
	}
}
</style>