<template>
    <section class="position-relative px-4 pb-3">
        <h3 class="text-left text-secondary font-weight-bolder mr-2 mb-4 mt-5">Configurações de entrada de
            mensagens</h3>

        <common-table-custom v-if="$role(['rules.get'])" :loading="loading.hall" v-bind="hallTableModel">
            <template v-slot:custom="item">
                <div>
                    <font-awesome-icon v-if="$role(['rules.update'])" v-b-tooltip="'Editar'" :hidden="loading.hall"
                                       class="my-1 mx-2 text-secondary"
                                       icon="fa-solid fa-pen-to-square" role="button" size="lg"
                                       style="outline: none" @click.prevent="handleEditRule(item)"/>
                    <font-awesome-icon v-if="$role(['rules.delete'])" v-b-tooltip="'Remover'" :hidden="loading.hall"
                                       class="my-1 mx-2 text-danger"
                                       icon="fa-solid fa-trash-can" role="button" size="lg"
                                       style="outline: none" @click.prevent="handleDeleteRule(item)"/>
                </div>
            </template>
        </common-table-custom>

        <div v-if="$role(['rules.create'])" class="d-flex justify-content-start my-5">
            <h5 class="text-left text-secondary font-weight-bolder d-flex align-items-center" role="button"
                @click="collapseRuleVisibility = !collapseRuleVisibility;">
                <span class="circle-icon mr-3">+</span>
                Adicionar uma Nova Regra
            </h5>
        </div>

        <b-collapse v-if="$role(['rules.create','rules.update'])" v-model="collapseRuleVisibility"
                    class="mt-2 position-relative">
            <loading v-if="loading.rule"></loading>
            <form @submit.prevent="saveRule">
                <div class="row">
                    <div class="col-12 my-2">
                        <input v-model="rule.name" class="form-control" placeholder="Nome da Regra" required
                               type="text">
                    </div>
                    <div class="col-12 col-md-6 my-2">
                        <select v-model="rule.group_id" class="form-control mr-3">
                            <option :value="null" hidden selected>Equipe</option>
                            <option v-if="teams.length === 0" :value="null" selected>Nenhuma opção disponível
                            </option>
                            <option v-for="team in teams" :key="`campaign-options-${team._id}`" :value="team._id">
                                {{ team.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-12 col-md-6 my-2">
                        <select v-model="rule.type" class="form-control">
                            <option :value="null" hidden selected>Tipo de Atendimento</option>
                            <option :value="'revision'">Revisão de respostas</option>
                            <option :value="'interaction'">Interações gerais</option>
                        </select>
                    </div>
                    <div class="col-12 col-md-6 my-2">
                        <select v-model="rule.phone_id" class="form-control">
                            <option v-if="phones.length == 0" disabled value="">Nenhuma opção disponível</option>
                            <option :value="null" hidden selected>Número de Disparo</option>
                            <option v-for="(phone, index) in phones" :key="index" :value="phone._id">
                                {{ formatPhone(phone.phone) }}
                            </option>
                        </select>
                    </div>
                    <div class="col-12 col-md-6 my-2">
                        <select v-model="rule.campaign_id" class="form-control">
                            <option :value="null" hidden selected>Campanha</option>
                            <option v-if="campaigns.length === 0" :value="null" hidden selected>Nenhuma opção
                                disponível
                            </option>
                            <option v-for="(campaign, index) in campaigns" :key="index" :value="campaign._id">
                                {{ campaign.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-12 col-md-4 col-lg-3 offset-md-8 offset-lg-9 my-2 d-flex">
                        <button v-if="rule.id != null" class="btn btn-outline-danger mr-2 flex-grow-1" @click="reset">
                            Cancelar
                        </button>
                        <button class="btn btn-primary flex-grow-1">Salvar</button>
                    </div>
                </div>
            </form>
        </b-collapse>
    </section>
</template>

<script>
import CommonTableCustom from "@/views/components/CommonTableCustom.vue";
import Loading from "@/views/components/Loading.vue";
import {hallTableModel} from "@/views/pages/dashboard/settings/constants.js";
import {BCollapse} from 'bootstrap-vue';
import api from "@/api";
import {formatPhone} from "@/api/functions";

const rule = {
    id: null,
    name: null,
    group_id: null,
    campaign_id: null,
    type: null,
    phone_id: null,
}

export default {
    components: {
        CommonTableCustom,
        BCollapse,
        Loading
    },
    data() {
        return {
            collapseRuleVisibility: false,
            hallTableModel,
            rule: Object.assign({}, rule),
            loading: {
                hall: false,
                rule: false
            },
            teams: [],
            campaigns: [],
            phones: []
        }
    },

    created() {
        this.getRules();
        this.getTeams();
        this.getPhones();
        this.getCampaigns();
    },

    watch: {
        collapseRuleVisibility: {
            handler(val) {
                if (!val) this.rule = Object.assign({}, rule);
            }
        }
    },

    methods: {
        formatPhone,
        handleEditRule(item) {
            Object.assign(this.rule, item.data);
            this.collapseRuleVisibility = true;
        },
        handleDeleteRule(item) {
            const {id} = item.data;

            this.$swal({
                title: 'Tem certeza?',
                text: 'Deseja remover essa regra?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Remover',
                confirmButtonColor: "#317f98",
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.delete(`/rules/${id}`);
                }
            })
                .then((result) => {
                    if (result.isConfirmed && result.value.data.type) {
                        const {type, message} = result.value.data;

                        if (type == 'success') {
                            this.getRules();
                        }
                        this.toast(message, type);
                    }
                })
                .catch((err) => {
                    console.log(`handleDeleteRule: ${err}`);
                    if (err.response) {
                        return this.toast(err.response.data.message);
                    }
                    return this.toast('Não foi possível remover regra.');
                })
        },
        saveRule() {
            const {id} = this.rule;
            this.loading.rule = true;

            api({
                method: id == null ? 'POST' : 'PUT',
                url: id == null ? '/rules' : `/rules/${id}`,
                data: this.rule
            })
                .then(response => {
                    const {type, message} = response.data;

                    if (type == 'success') {
                        this.getRules();
                        this.reset();
                    }

                    this.toast(message, type);
                    {
                        this.$swal({
                            title: 'Regra',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: "#317f98",
                            text: message,
                            icon: type
                        });
                    }
                })
                .catch(err => {
                    if (err.response) {
                        return this.toast(err.response.data.message);
                    }
                    return this.toast('Não foi possível salvar regra.');
                })
                .finally(() => this.loading.rule = false);
        },

        getRules() {
            this.loading.hall = true;
            const params = {all: 1}
            api.get('/rules', {params})
                .then(response => {
                    this.hallTableModel.items = response.data.body;
                })
                .catch(err => {
                    this.toast(err.data.message, err.data.type);
                })
                .finally(() => this.loading.hall = false);
        },

        getTeams() {
            api.get('/groups', {params: {only: ['_id', 'name'], all: 1}})
                .then(response => {
                    this.teams = response.data.body;
                })
                .catch(err => {
                    this.$swal('Erro ao carregar equipes', err.data.message, 'error');
                })
        },

        getPhones() {
            api.get('/phones')
                .then(response => {
                    this.phones = response.data.body;
                })
                .catch(err => {
                    this.$swal('Erro ao carregar números de disparos', err.data.message, 'error');
                })
        },

        getCampaigns() {
            api.get('/campaigns', {params: {only: ['_id', 'name'], all: 1}})
                .then(response => {
                    this.campaigns = response.data.body;
                })
                .catch(err => {
                    this.$swal('Erro ao carregar campanhas', err.data.message, 'error');
                })
        },

        reset() {
            this.rule = {};
            this.loading.rule = false;
            this.collapseRuleVisibility = false;
        },

        toast(message, type = 'error') {
            this.$swal({
                title: 'Regra',
                confirmButtonText: 'Ok',
                confirmButtonColor: "#317f98",
                text: message,
                icon: type
            });
        }
    }
}
</script>
