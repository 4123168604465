<template>
    <div class="otp-input">
        <div v-if="loading">
            <loading/>
        </div>
        <div ref="otpCont">
            <input
                type="text"
                class="digit-box"
                v-for="(el, ind) in digits"
                :key="el+ind"
                :id="el+ind"
                :autofocus="ind == 0"
                maxlength="1"
                @input="handleInput($event, ind)"
                v-model="digits[ind]"
            >
        </div>
        <div>
            <b-button
                class="send flex-grow-1"
                type="submit"
                variant="primary"
                @click="sendCode">
                Enviar
            </b-button>
        </div>
    </div>
</template>

<script >
    import {BButton} from "bootstrap-vue"
    import api from '@/api';
    import Loading from "@/views/components/Loading.vue";

    export default {
        data() {
            return {
                digits: [],
                loading: false
            }
        },
        components: {
            BButton,
            Loading
        },
        created() {
            if (this.$props.default && this.$props.default.length === this.$props.digitCount) {
                for (let i =0; i < this.$props.digitCount; i++) {
                    this.digits[i] = this.$props.default.charAt(i)
                }
            } else {
                for (let i =0; i < this.$props.digitCount; i++) {
                    this.digits[i] = null;
                }
            }
        },
        props: {
            default: String,
            digitCount: {
                type: Number,
                required: true
            },
            endpoint: String
        },
        methods: {
            toast(title, message, type = 'danger') {
                if (type == 'error') type = 'danger';
                this.$bvToast.toast(message, {title: title, variant: type})
            },
            handleInput(event, index) {
                if (event.inputType === "deleteContentBackward") {
                    this.digits[index] = null;
                    if (index != 0) {
                        this.$refs.otpCont.children[index-1].focus();
                    } else {
                        this.$refs.otpCont.children[index].focus()
                    }
                    return;
                } else if (event.inputType === "insertText" && (new RegExp('^([0-9])$')).test(event.data)) {
                    if (this.$refs.otpCont.children[index].id == this.$refs.otpCont.children[this.digits.length - 1].id) {
                        return
                    } else {
                        this.digits[index] = event.data;
                        this.$refs.otpCont.children[index+1].focus();
                    }
                } else {
                    this.$refs.otpCont.children[index].value = null
                    this.digits[index] = null
                    this.$refs.otpCont.children[index].focus()
                }
            },
            sendCode() {

                let code = ''
                for (let i =0; i < this.$props.digitCount; i++) {
                    code += this.digits[i];
                }
                this.loading = true
                let params = {
                    'user_id': this.user._id,
                    'code': code
                }
                api.post(this.endpoint, {...params})
                .then((res) => {
                    console.log(res)
                    const {message, body} = res.data;

                    this.toast(message, body, 'info');

                    this.loading = false
                    this.$store.dispatch("user/loadSession").then(() =>
										setTimeout(() => {
											try {
												this.$router.replace({
													name: this.$route.name,
													params: { tab: "checkout" }
												});
											} catch (error) {
												// eslint-disable-next-line no-undef
												if (!(error instanceof NavigationDuplicated)) {
													throw error;
												}
											}
										}, 100)
									);

                }).catch((error) => {
                    console.log(error);
                    var message = "Houve um erro ao validar seu email.";

                    if (error.response) {
                        message += error.response.data.errors[0];
                    }
                    this.toast(message);
                    this.loading = false
                })
            }
        },

    }


    // const otpCont = ref(null)

</script>

<style scoped>
.digit-box {
    height: 5rem;
    width: 5rem;
    border: 2px solid black;
    display: inline-block;
    border-radius: 5px;
    margin: 5px;
    padding: 15px 15px 15px 22px;
    color: black;
    font-size: 3rem;
}

.digit-box:focus {
    outline: 3px solid black;
}

</style>
