<template>
    <section class="position-relative py-2 px-4">
        <loading v-if="loading"></loading>
        <h3 class="text-left text-secondary font-weight-bolder mr-2 mb-4 mt-5">Editar meu perfil</h3>

        <validation-observer ref="profileValidation">
            <form @submit.prevent="handleSave">
                <div class="row my-3">
                    <div
                        class="col-12 col-md-3 d-flex align-items-center justify-content-center justify-content-md-start">
                        <resizable-image-input
                            :options="{'aspect-ratio': 1}"
                            :src="profile.profile"
                            @input="(e) => profile.file = e"
                        />
                    </div>
                    <div class="col-12 col-md-9 col-lg-6 d-flex flex-column text-left">
                        <div class="form-group">
                            <validation-provider
                                #default="{ errors }"
                                name="nome"
                                rules="required">
                                <input
                                    v-model="profile.name"
                                    :class="`form-control ${errors.length > 0 ? 'is-invalid': ''}`"
                                    name="name"
                                    placeholder="Nome"
                                    type="text">
                                <small class="text-danger">
                                    {{ errors[0] }}
                                </small>
                            </validation-provider>
                        </div>
                        <div class="form-group">
                            <validation-provider
                                #default="{ errors }"
                                name="biografia"
                                rules="min:50">
                                <textarea
                                    v-model="profile.biography"
                                    :class="`form-control ${errors.length > 0 ? 'is-invalid': ''}`"
                                    name="byography"
                                    placeholder="Olá! Eu me chamo Maria, sou representante de vendas e vou cuidar do seu atendimento. Em posso ser útil hoje? "
                                    rows="5"></textarea>
                                <small class="text-danger">
                                    {{ errors[0] }}
                                </small>
                            </validation-provider>
                        </div>
                    </div>
                </div>

                <h6 class="text-dark text-left font-weight-bold my-3 mt-5">
                    Dados Pessoais
                </h6>

                <div>
                    <div class="row my-2">
                        <div class="col-12 col-md-6">
                            <input v-model="profile.email" class="form-control" disabled placeholder="Email" type="email">
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group text-left">
                                <validation-provider
                                    #default="{ errors }"
                                    name="telefone 1"
                                    rules="min:18|required">
                                    <input
                                        v-model="profile.phone_1"
                                        v-mask="['+## (##) #####-####', '+## (##) ####-####']"
                                        :class="`form-control ${errors.length > 0 ? 'is-invalid': ''}`"
                                        name="telefone-1"
                                        placeholder="Telefone 1"
                                        type="tel">
                                    <small class="text-danger">
                                        {{ errors[0] }}
                                    </small>
                                </validation-provider>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 text-left">
                            <div class="form-group text-left">
                                <validation-provider
                                    #default="{ errors }"
                                    name="telefone 2"
                                    rules="min:18">
                                    <input
                                        v-model="profile.phone_2"
                                        v-mask="['+## (##) #####-####', '+## (##) ####-####']"
                                        :class="`form-control ${errors.length > 0 ? 'is-invalid': ''}`"
                                        name="telefone-2"
                                        placeholder="Telefone 2"
                                        type="tel">
                                    <small class="text-danger">
                                        {{ errors[0] }}
                                    </small>
                                </validation-provider>
                            </div>
                        </div>
                        <template v-if="type === 'owner'">
                            <div class="col-12 col-md-6 text-left">
                                <div class="form-group text-left">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="document"
                                        rules="min:14">
                                        <input
                                            v-model="profile.document"
                                            v-mask="['###.###.###-##', '##.###.###/####-##']"
                                            :class="`form-control ${errors.length > 0 ? 'is-invalid': ''}`"
                                            name="document"
                                            placeholder="CPF/CNPJ">
                                        <small class="text-danger">
                                            {{ errors[0] }}
                                        </small>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group text-left">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="zip"
                                        rules="min:9">
                                        <input
                                            @blur="getAddresses"
                                            v-model="profile.zip"
                                            v-mask="['#####-###']"
                                            :class="`form-control ${errors.length > 0 ? 'is-invalid': ''}`"
                                            name="zip"
                                            placeholder="CEP">
                                        <small class="text-danger">
                                            {{ errors[0] }}
                                        </small>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 text-left">
                                <div class="form-group text-left">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="address"
                                        rules="min:6">
                                        <input
                                            v-model="profile.address"
                                            :class="`form-control ${errors.length > 0 ? 'is-invalid': ''}`"
                                            name="address"
                                            placeholder="Endereço">
                                        <small class="text-danger">
                                            {{ errors[0] }}
                                        </small>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group text-left">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="city"
                                        rules="min:3">
                                        <input
                                            v-model="profile.city"
                                            :class="`form-control ${errors.length > 0 ? 'is-invalid': ''}`"
                                            name="city"
                                            placeholder="Cidade">
                                        <small class="text-danger">
                                            {{ errors[0] }}
                                        </small>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 text-left">
                                <div class="form-group text-left">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="state"
                                        rules="min:2">
                                        <select
                                            v-model="profile.state"
                                            :class="`form-control ${errors.length > 0 ? 'is-invalid': ''}`"
                                            name="state">
                                            <option :value="undefined">Estado</option>
                                            <option v-for="(state, index) in states" :value="index" :key="index">
                                                {{ state }}
                                            </option>
                                        </select>
                                        <small class="text-danger">
                                            {{ errors[0] }}
                                        </small>
                                    </validation-provider>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="d-flex justify-content-end my-1">
                        <span v-b-modal.updatePasswordModal class="text-blue" role="button">Alterar senha</span>
                    </div>
                </div>

                <div class="row align-items-center justify-content-end">
                    <div class="col-12 col-sm-6 col-md-2 d-flex mt-3">
                        <button :disabled="!profile._id" class="btn flex-grow-1 btn-primary">Salvar</button>
                    </div>
                </div>
            </form>
        </validation-observer>
        <update-password-modal/>
    </section>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate";
import UpdatePasswordModal from '@/views/pages/dashboard/settings/components/UpdatePasswordModal.vue';
import Loading from '@/views/components/Loading.vue';
import {required} from "@validations";
import api from '@/api';
import ResizableImageInput from '@/views/components/ResizableImageInput.vue';
import { states } from '@/api/constants';

export default {
    components: {
        UpdatePasswordModal,
        Loading,
        ResizableImageInput,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            required,
            loading: false,
            profile: {},
            states
        }
    },
    created() {
        this.fetchData();
    },
    computed: {
        type() {
            return this.$store.state.user.user.type;
        }
    },
    methods: {
        getAddresses() {
            if (this.profile.zip && this.profile.zip.length === 9) {
                api.get(`https://viacep.com.br/ws/${this.profile.zip.replace('-', '')}/json/`)
                    .then(({ data }) => {
                        const { logradouro, localidade, uf } = data;
                        this.profile.address = logradouro;
                        this.profile.city = localidade;
                        this.profile.state = uf;
                    })
                    .catch(() => {
                        this.profile.address = '';
                        this.profile.city = '';
                        this.profile.state = '';
                    });
            }
        },
        fetchData() {
            if (!this.$store.state.user.session) {
                return;
            }
            this.loading = true;
            api.get('/me')
                .then(response => {
                    const {type, message, body, errors} = response.data;

                    if (type === 'success') {
                        return this.profile = body;
                    }
                    this.$swal(message, errors.length > 0 ? errors[0] : '', 'error');
                })
                .catch(error => {
                    if (error.response) {
                        const {message, errors} = error.response.data;
                        return this.$swal(message, errors.length > 0 ? errors[0] : '', 'error');
                    }
                    this.$swal("Houve um erro inesperado", "Não foi possível carregar os seus dados, tente novamente mais tarde.", 'error');
                })
                .finally(() => this.loading = false)
        },
        handleSave() {
            this.$refs.profileValidation.validate().then(success => {
                if (!success) return;
                this.loading = true;
                const {name, biography, file, phone_1, phone_2, instance, token, zip, address, city, state, document} = this.profile;
                const data = {name, biography, 'profile_img': file, phone_1, phone_2, instance, token, zip, address, city, state, document};

                const formData = new FormData();
                Object.entries(data).filter(v => Boolean(v[1])).forEach(([key, value]) => formData.append(key, value));

                api.post('/me', formData)
                    .then(response => {
                        const {type, errors, message, body} = response.data;

                        if (type === 'success') {
                            this.$swal({
                                icon: "success",
                                title: 'Perfil atualizado!',
                                text: 'O perfil foi atualizado com sucesso!',
                                showCancelButton: false,
                                confirmButtonText: 'Ok',
                                confirmButtonColor: "#317f98",
                            });

                            this.$store.commit('user/SET', body);
                            return this.profile = body;
                        }
                        this.$swal(message, errors.length > 0 ? errors[0] : '', 'error');
                    })
                    .catch(error => {
                        if (error.response) {
                            const {message, errors} = error.response.data;
                            return this.$swal(message, errors.length > 0 ? errors[0] : '', 'error');
                        }
                        this.$swal('Erro ao atualizar perfil', 'Não foi possível atualizar o perfil, tente novamente mais tarde.');
                    })
                    .finally(() => this.loading = false);
            });
        }
    }
}
</script>
