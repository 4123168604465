<template>
	<section class="position-relative px-4 pb-3">
		<section class="row my-5">
			<div class="col-md-6 col-xl-3 d-flex flex-column flex-grow-1 align-items-start pb-4">
				<h3 class="text-secondary font-weight-bolder">Plano atual</h3>
				<span>{{ user.plan.name }}</span>
				<span v-if="user.next_plan_id" class="text-secondary">
					Próximo plano: {{ user.next_plan.name }}
				</span>
				<a v-if="!user.next_plan_id" v-b-modal.changePlanModal class="text-blue mt-2">
					Mudar plano
				</a>
			</div>
			<div class="col-md-6 col-xl-2 d-flex flex-column flex-grow-1 align-items-start pb-4">
				<h3 class="text-secondary font-weight-bolder">Status</h3>
				<span v-if="status === 'pendente'" class="text-orange">Pendente</span>
				<span v-if="status === 'efetuado'" class="text-blue">Efetuado</span>
			</div>
			<div class="col-md-6 col-xl-3 d-flex flex-column flex-grow-1 align-items-start pb-4">
				<h3 class="text-secondary font-weight-bolder">Limites</h3>
                <div class="w-100 mb-3 d-flex">
                    <b-button class="btn mr-2 pb-10" :class="extra_phones ? 'btn-primary' : 'btn-disabled'" :disabled="!extra_phones" style="width: 14.5%;height: 30px;" title="Diminuir o número máximo de números de telefone" @click="openModal('diminuir', 'números telefonicos')">
                        -
                    </b-button>
                    <section class="w-75">
                        <div class="progress">
                            <div
                                class="progress-bar"
                                role="progressbar"
                                :aria-valuenow="user.used_phones"
                                aria-valuemin="0"
                                :aria-valuemax="max_phones"
                                :style="`width: ${(user.used_phones / max_phones) * 100}%`"
                            >
                                {{ user.used_phones }}
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <small>0</small>
                            <small>Números</small>
                            <small>{{ extra_phones ? plan_max_phones + ' + ' +  extra_phones : plan_max_phones}}</small>
                        </div>
                    </section>
                    <b-button class="btn btn-primary ml-2 pb-10" style="width: 14.5%;height: 30px;" title="Aumentar o número máximo de números de telefone" @click="openModal('aumentar', 'números telefonicos')">
                        +
                    </b-button>
                </div>

                <div class="w-100 mb-3 d-flex">
                    <b-button class="btn mr-2 pb-10" :class="extra_agents ? 'btn-primary' : 'btn-disabled'" :disabled="!extra_agents" style="width: 14.5%;height: 30px;" title="Diminuir o número máximo de números de agentes" @click="openModal('diminuir', 'agentes')">
                        -
                    </b-button>
                    <section class="w-75">
                        <div class="progress">
                            <div
                                class="progress-bar"
                                role="progressbar"
                                :aria-valuenow="user.used_agents"
                                aria-valuemin="0"
                                :aria-valuemax="max_agents"
                                :style="`width: ${max_agents <= 0 ? 100 : (user.used_agents / max_agents) * 100}%`"
                            >
                                {{ user.used_agents || "" }}
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between w-100">
                            <small>0</small>
                            <small>Agentes</small>
                            <small>{{ extra_agents ? plan_max_agents + ' + '  + extra_agents : plan_max_agents}}</small>
                        </div>
                    </section>
                    <b-button class="btn btn-primary ml-2 pb-10" style="width: 14.5%;height: 30px;" title="Diminuir o número máximo de números de agentes" @click="openModal('aumentar', 'agentes')">
                        +
                    </b-button>
                </div>

			</div>
			<div
				class="col-md-6 col-xl-3 offset-xl-1 d-flex flex-column flex-grow-1 align-items-start pb-4"
			>
				<h3 class="text-secondary font-weight-bolder">Cobrança</h3>
				<p class="text-left m-0">
					<template v-if="!user.next_plan_id">
						O pagamento está {{ status }} e a cobrança da sua assinatura ocorrerá no dia
						<b>{{ expires.format("DD/MM/YYYY") }}</b>
						no valor de
						<b>{{ user.plan.price }}</b>
						.
					</template>
					<template v-else>
						O pagamento está {{ status }} e a cobrança da sua assinatura ocorrerá no dia
						<b>{{ expires.format("DD/MM/YYYY") }}</b>
						no valor de
						<b>{{ user.next_plan.price }}</b>
						.
					</template>
				</p>
				<a role="button" class="text-blue mt-2" v-b-modal.paymentMethodModal>
					Mudar forma de pagamento
				</a>
				 <a role="button" class="text-secondary" @click="cancel">Cancelar assinatura</a>
			</div>
		</section>

		<common-table-custom title="Histórico de Pagamentos" :loading="loading" v-bind="plansTable">
			<template v-slot:filters>
				<div class="d-flex align-items-center">
					<div class="flex-shrink">
						<select
							v-model="plansTable.filters.order_column"
							class="form-control like-dropdown my-1"
						>
							<option :value="null" hidden selected>Filtrar por</option>
							<option value="name">Nome</option>
							<option value="type">Tipo</option>
							<option value="created_at">Data de Criação</option>
						</select>
					</div>
					<div class="flex-shrink">
						<select
							v-model="plansTable.filters.order"
							class="form-control like-dropdown mx-md-2 my-1"
						>
							<option :value="null" hidden selected>Classificar por</option>
							<option value="asc">Ascendente</option>
							<option value="desc">Descendente</option>
						</select>
					</div>
					<div
						class="input-group input-group-merge search my-1 w-100 flex-grow-1"
						role="group"
						style="width: 300px !important"
					>
						<div class="input-group-prepend">
							<div class="input-group-text">
								<feather-icon icon="SearchIcon" size="25" />
							</div>
						</div>
						<input
							v-model="plansTable.filters.search"
							class="form-control"
							placeholder="Buscar registro"
							type="text"
						/>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<td class="text-left">Total</td>
				<td>{{ plansTable.misc ? plansTable.misc.total : 0 }}</td>
				<td :colspan="5" class="text-right">
					<b-link
						v-if="
							plansTable.misc &&
							plansTable.misc.current_page !== plansTable.misc.last_page
						"
					>
						+ Mostrar mais
					</b-link>
				</td>
			</template>
		</common-table-custom>

        <b-modal
            id="checkoutForm"
            centered
            body-class="position-unset"
            content-class="p-4 position-relative"
            footer-border-variant="light"
            header-border-variant="light"
            hide-footer
            size="xl"
        >
            <checkout-form
                class="my-4"
                :plan="this.user.plan"
                :type="this.user.plan_type ?? 'monthly'"
                @submit="submit"
                :disabled="loading"
                :extraValue="paymentPlanAdd"
                :extraValueNow="paymentDefinedValue"
                :showTicket="false"
                :externalDiscount="discount"
            />
        </b-modal>

		<change-plan-modal />
        <payment-method-modal />
	</section>
</template>

<script>
import { plansTableModel } from "@/views/pages/dashboard/settings/constants.js";
import CommonTableCustom from "@/views/components/CommonTableCustom.vue";
import ChangePlanModal from "./components/ChangePlanModal.vue";
import PaymentMethodModal from "./components/PaymentMethodModal.vue";
import CheckoutForm from "./components/CheckoutForm.vue";
import {BButton} from "bootstrap-vue";
import api from "@/api";
import moment from "moment";

export default {
	components: {
		CommonTableCustom,
		ChangePlanModal,
        BButton,
        CheckoutForm,
        PaymentMethodModal
	},
	data() {
		return {
			loading: false,
			plansTable: plansTableModel,
			payments: [],
            coupon: '',
            number: 1,
            paymentUrl: '',
            paymentKind: '',
            paymentDefinedValue: null,
            paymentPlanAdd: null,
            discount: null,
            paymentAllowBoleto: true
		};
	},
	computed: {
		user() {
			return this.$store.getters["user/get"];
		},
		max_phones() {
			return this.extra_phone_numbers ? this.user.plan.max_phones + this.extra_phone_numbers : this.user.plan.max_phones;
		},
		max_agents() {
			return this.extra_agents ? this.user.plan.max_agents + this.extra_agents : this.user.plan.max_agents;
		},
        plan_max_phones() {
            return this.user.plan.max_phones;
        },
        plan_max_agents() {
            return this.user.plan.max_agents;
        },
        extra_phones() {
            return this.user?.extra_phone_numbers
        },
        extra_agents() {
            return this.user?.extra_agents
        },
		expires() {
			return moment(this.user.expires_at);
		},
		status() {
			return moment().isBefore(this.expires) ? "efetuado" : "pendente";
		}
	},
	methods: {
        toast(message, type = "danger") {
            this.$root.$bvToast.toast(message, {
                title: 'Método de pagamento',
                autoHideDelay: 2000,
                variant: type
            })
        },
		getPayments() {
			this.loading = true;
			api.get("/me/payments")
				.then(({ data }) => {
					const { body, misc } = data;
					this.plansTable.items = body;
					this.plansTable.misc = misc;
				})
				.finally(() => {
					this.loading = false;
				});
		},
        async openModal(action, field) {
            const vm = this
            vm.loading = true

            await this.$swal({
                title: `Insira quantos ${field} deseja ${action}`,
                text: 'Valor mínimo 1 unidade',
                // html: `${action == 'diminuir' ? '' :
                //     "<input type='text' name='inputCoupon' class='form-control' style='width: 170px; margin:  30px 0px 0 100px; height: 40px;' placeholder='Insira seu cupom aqui' />"}`,
                input: 'number',
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                inputAttributes: {
                    min: '1',
                    value: '1'
                },
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
            }).then((result) => {
                if (!result.isConfirmed) {
                    vm.loading = false
                    return
                }
                vm.number = result.value ? parseInt(result.value) : 1
                api.post('/me/modify-plan', {
                    plan_id: vm.user.plan_id,
                    action: action,
                    field: field,
                    number: vm.number,
                }).then((res) => {
                    if (res.status >= 400) {
                        vm.$swal({
                            title: "Erro ao alterar o plano",
                            text: res.data.message,
                            type: "error",
                            confirmButtonText: "Ok",
                            showCancelButton: false,
                        })
                        vm.loading = false
                    } else if (!res.data.body.can_increase) {
                        vm.$swal({
                            title: "Erro ao alterar o plano",
                            text: res.data.message,
                            type: "error",
                            confirmButtonText: "Ok",
                            showCancelButton: false,
                        })
                        vm.loading = false
                    }else {
                        vm.loading = false
                        vm.paymentDefinedValue = parseFloat(res.data.body.value_now)
                        vm.paymentPlanAdd = parseFloat(res.data.body.value_plan)
                        vm.discount = parseFloat(res.data.body.value_plan)
                        vm.$swal({
                            title: "Confirmar",
                            text: res.data.message,
                            confirmButtonText: "Confirmar",
                            cancelButtonText: "Cancelar",
                            showCancelButton: true,
                            reverseButtons: true,
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (action == 'aumentar' && result.isConfirmed) {
                                vm.paymentAllowBoleto = false
                                vm.discount = 0
                                vm.paymentKind = field == 'números telefonicos' ? 'phone' : 'agent'
                                vm.$bvModal.show('checkoutForm')

                            } else if (action == 'diminuir' && result.isConfirmed) {
                                vm.paymentPlanAdd = 0
                                vm.paymentAllowBoleto = false
                                vm.paymentUrl = '/me/modify-plan/confirm/decrease'
                                vm.paymentKind = field == 'números telefonicos' ? 'phone' : 'agent'
                                vm.$bvModal.show('checkoutForm')

                            }

                        }).catch((err) => {
                            console.log(err)
                            vm.$swal({
                                title: "Erro ao alterar o plano",
                                text: "Ocorreu um erro ao tentar alterar seu plano, tente novamente mais tarde.",
                                type: "error",
                                confirmButtonText: "Ok",
                                showCancelButton: false,
                            })
                        })
                    }

                }).catch((err) => {
                    console.log(err)
                    vm.loading = false
                })
            })
        },
		cancel() {
			this.$swal({
				title: "Cancelar assinatura",
				text: "Tem certeza que deseja cancelar a assinatura do plano atual?",
				confirmButtonText: "Sim",
				cancelButtonText: "Cancelar",
				showCancelButton: true,
				reverseButtons: true
			}).then((result) => {
				if (result.value) {
					api.delete("/purchases")
						.then(({ data }) => {
							this.$swal({
								title: "Assinatura cancelada",
								text: data.message,
								type: "success",
								confirmButtonText: "Ok"
							}).then(() => {
								this.$store.dispatch("user/loadSession");
							});
						})
						.catch(() => {
							this.$swal({
								title: "Erro ao cancelar assinatura",
								text: "Ocorreu um erro ao cancelar a sua assinatura, tente novamente mais tarde.",
								type: "error",
								confirmButtonText: "Ok"
							});
						});
				}
			});
		},
        submit(paymentMethod, address, cardToken = undefined, couponId, installments = 1) {
            const vm = this;
            vm.loading = true;

            api.post(this.paymentUrl, {
                payment_method: paymentMethod,
                address,
                card_token: cardToken,
                quantity: vm.number,
                defined_value_now: vm.paymentDefinedValue,
                defined_value_add: vm.paymentPlanAdd,
                discount: vm.discount,
                kind: vm.paymentKind,
                coupon_id: couponId,
                installments
            }).then(( data ) => {
                this.$bvModal.hide('checkoutForm')
                const body = data.body;
                if (body && body.payment.status === 'paid') {
                    vm.toast('Pagamento realizado com sucesso!', 'success');
                } else if (body && body.payment.status === 'pending') {
                    vm.toast('Pagamento pendente, aguarde a confirmação do pagamento.', 'warning');
                } else {
                    vm.toast('Não foi possível realizar o pagamento.', 'danger');
                }

            }).catch(error => {
                vm.$bvModal.hide('checkoutForm')
                vm.toast(error.message || 'Não foi possível realizar o pagamento.', 'danger');

                this.loading = false;
            }).finally(() => {
                vm.$bvModal.hide('checkoutForm')
                vm.loading = false;
                // this.$router.go()
            });
        }
	},
	created() {
		this.getPayments();
	}
};
</script>

<style lang="scss" scoped>
.progress {
	width: 100%;
	height: 30px;
	position: relative;
}
</style>
