<template>
	<section class="position-relative px-4 pb-3">
		<div v-if="this.lastPayment.status == 'pending'" class="row my-4">
			<div class="col-12 col-xl-6 offset-xl-3 my-2 text-left">
				<article class="border top-border-card border-primary p-4 rounded">
					<p class="h5 mb-4">
						<font-awesome-icon icon="fa-solid fa-clock" class="mr-2" size="sm" />
						Aguardando Confirmação de Pagamento
					</p>

					<div class="alert alert-secondary mb-3">
						<b>Obrigado por sua assinatura!</b>

						<p class="m-0">
							Seu pedido foi recebido com sucesso e estamos aguardando a confirmação
							do pagamento.
						</p>
					</div>

					<section class="mb-3">
						<b>Detalhes do Pedido:</b>
						<ul class="list-group">
							<li class="list-group-item">
								<div class="d-flex flex-column">
									<p class="mb-1">
										Plano:
										<b>{{ lastPayment.plan_name }} ({{ planType }})</b>
									</p>

									<p class="mb-1">
										Valor:
										<b>
											{{ currencyFormatter.format(lastPayment.value) }}
										</b>
									</p>

									<p class="mb-1">
										Pedido:
										<b>
											{{ lastPayment.pagarme_id ?? lastPayment._id }}
										</b>
									</p>

									<p class="mb-1">
										Data de Emissão:
										<b>
											{{
												moment(lastPayment.created_at).format("DD/MM/YYYY")
											}}
										</b>
									</p>

									<a
										role="button"
										@click="cancel"
										:disabled="loading"
										class="cursor-pointer"
									>
										Escolher outro plano
									</a>
								</div>
							</li>
						</ul>
					</section>

					<section class="mb-3">
						<b>Intruções:</b>

						<div class="alert alert-secondary">
							O pagamento via boleto pode levar até 3 dias úteis para ser processado.
							Você receberá um e-mail de confirmação assim que o pagamento for
							aprovado.
						</div>
					</section>

					<section v-if="url" class="mb-3">
						<a
							target="_blank"
							:href="url"
							:disabled="loading"
							class="btn btn-primary w-100"
						>
							Baixar Boleto
						</a>
					</section>

					<section>
						<b>Perguntas Frequentes:</b>
						<ul class="list-group">
							<li
								class="list-group-item d-flex justify-content-between align-items-start"
							>
								<div class="ms-2">
									<div class="font-weight-bold">
										Como faço para pagar o boleto?
									</div>
									Você pode pagar o boleto em qualquer banco, casa lotérica ou
									pelo seu internet banking.
								</div>
							</li>
							<li
								class="list-group-item d-flex justify-content-between align-items-start"
							>
								<div class="ms-2">
									<div class="font-weight-bold">
										Quanto tempo leva para o pagamento ser confirmado?
									</div>
									O tempo de confirmação pode variar, mas geralmente leva até 3
									dias úteis.
								</div>
							</li>
						</ul>
					</section>
				</article>
			</div>
		</div>

		<template v-else>
			<p class="text-muted mb-0">
				Seu plano expirou em
				{{ moment(expires_at).format("DD/MM/YYYY") }}
				e não encontramos um novo pagamento.
			</p>
			<template v-if="lastPayment && lastPayment.payment_form === 'boleto'">
				<p class="text-muted">O boleto foi enviado para o e-mail {{ this.user.email }}.</p>
				<a v-if="url" :href="url" class="btn btn-primary" target="_blank">Pagar o boleto</a>
			</template>
			<template v-else>
				<p class="text-muted">Seu pagamento foi recusado.</p>
				<b-button
					class="btn btn-primary mr-3"
					@click="showPaymentQuestion"
					v-if="user.pagarme_plan_id"
				>
					Tentar novamente
				</b-button>
				<b-button class="btn btn-primary mr-3" v-b-modal.changePlanModal>
					Mudar plano
				</b-button>
				<b-button
					class="btn btn-primary"
					v-b-modal.paymentMethodModal
					v-if="!user.pagarme_plan_id"
				>
					Escolher forma de pagamento
				</b-button>
				<b-button
					class="btn btn-primary"
					@click="showCancelQuestion"
					v-if="user.pagarme_plan_id"
				>
					Cancelar assinatura
				</b-button>
			</template>
		</template>
	</section>
</template>

<script>
import api from "@/api";
import { planTypes, currencyFormatter } from "@/api/constants";
import moment from "moment";

export default {
	data() {
		return {
			url: null,
			loading: false,
			currencyFormatter
		};
	},
	computed: {
		lastPayment() {
			return this.user.last_payment;
		},
		expires_at() {
			return this.user.expires_at;
		},
		plan() {
			return this.user.plan;
		},
		planType() {
			return this.lastPayment.type in planTypes
				? planTypes[this.lastPayment.type]
				: planTypes["monthly"];
		}
	},
	methods: {
		moment,
		toast(message, type = "danger") {
			this.$bvToast.toast(message, {
				title: "Checkout",
				autoHideDelay: 2000,
				variant: type
			});
		},
		showPaymentQuestion() {
			this.$bvModal
				.msgBoxConfirm("Deseja tentar novamente o pagamento?", {
					title: "Tentar novamente",
					okTitle: "Sim",
					cancelTitle: "Não",
					centered: true
				})
				.then((value) => {
					if (value) {
						api.get("/purchases/retry-payment").then(({ data }) => {
							const { body } = data;

							const errors = {
								"Could not create credit card. The card verification failed.":
									"O cartão de crédito não foi aceito. Verifique os dados e tente novamente."
							};

							if (body.status === "paid") {
								this.toast("Pagamento realizado com sucesso!", "success");
								this.$store.dispatch("user/loadSession").then(() => {
									this.$router.push({ name: "campaigns" });
								});
							} else if (body.status === "pending") {
								this.toast(
									"Pagamento pendente, aguarde a confirmação do pagamento.",
									"warning"
								);
							} else {
								this.toast(
									errors[body.status] ||
										"Pagamento não realizado, tente novamente.",
									"danger"
								);
							}
						});
					}
				});
		},
		cancel() {
			this.$bvModal
				.msgBoxConfirm(
					"Tem certeza que deseja cancelar esse pedido e escolher outro plano?",
					{
						title: "Cancelar assinatura",
						okTitle: "Tenho certeza",
						cancelTitle: "Cancelar",
						centered: true
					}
				)
				.then((value) => {
					if (value) {
						api.delete("/purchases").then(({ data }) => {
							const { type, message } = data;
							if (type === "error") {
								this.toast(message, "danger");
							}

							this.$store.dispatch("user/loadSession");
						});
					}
				});
		},
	},
	mounted() {
		if (this.lastPayment && this.lastPayment.payment_form === "boleto") {
			this.loading = true;
			api.get("/purchases/last-payment")
				.then(({ data }) => {
					this.url = data.body.pdf;
				})
				.finally(() => (this.loading = false));
		}
	}
};
</script>
