<template>
	<section class="row position-relative">
		<loading v-if="loading.save" />

		<div class="col-12 col-lg-3 my-4">
			<img
				:src="integration.img"
				:alt="`Integração com a ${integration.name}. Importe clientes e faça envio de mensagens automáticas`"
				height="50"
			/>
			<p class="my-4 text-center">
				{{ integration.description }}
			</p>
			<!-- <span class="text-primary">150 clientes recebidos</span> -->
		</div>

		<div class="col-12 col-lg-9 my-4">
			<section v-if="loading.integration">
				<p>Estabelecendo conexão com {{ integration.name }}...</p>
			</section>
			<form @submit.prevent="save" v-else class="row">
				<div class="col-12">
					<div class="d-flex align-items-center justify-content-between">
						<div class="d-flex align-items-center">
							<h5 class="text-secondary font-weight-bolder mb-0 mr-2">Integração</h5>
							<font-awesome-icon
								@click="sync"
								role="button"
								:spin="loading.save"
								icon="fa-solid fa-rotate"
								v-b-tooltip="'Sincronizar'"
							/>
						</div>

						<span @click="toggle" role="button" class="text-danger">
							{{ toggleTitle }}
						</span>
					</div>
				</div>

				<div class="col-12 col-md-4 my-2">
					<b-form-group label="ID da Loja:" class="text-left">
						<b-form-input
							:value="integrationUser"
							readonly
							placeholder="Usuário"
							required
							type="text"
						/>
					</b-form-group>
				</div>

				<div class="col-12 col-md-8 my-2">
					<b-form-group label="Token de Acesso:" class="text-left">
						<b-form-input
							:value="integrationToken"
							readonly
							placeholder="Token"
							required
							type="text"
						/>
					</b-form-group>
				</div>

				<div class="col-12 my-2 d-flex flex-column align-items-start">
					<b-form-group label="Números de Disparo:" class="text-left w-100">
						<v-select
							:options="phones"
							:disabled="data.all_phones"
							multiple
							placeholder="Selecione os números de disparo"
							:selectable="(option) => !data.phones.includes(option._id)"
							:get-option-label="getLabelPhone"
							:reduce="(option) => option._id"
							v-model="data.phones"
							class="w-100"
							:loading="loading.phones"
						>
							<span slot="no-options">Desculpe, não há opções correspondentes.</span>
						</v-select>
					</b-form-group>

					<b-form-checkbox
						:value="true"
						:unchecked-value="false"
						class="mb-2"
						v-model="data.all_phones"
					>
						Todos os Números
						<font-awesome-icon
							role="button"
							icon="fa-solid fa-circle-info"
							v-b-tooltip="
								'Todos os números de disparo cadastrados serão utilizados nas operações, com prioridade sempre dada aos números que estão atualmente conectados.'
							"
						/>
					</b-form-checkbox>
				</div>

				<div class="col-12" v-if="firstIntegration">
					<div class="alert alert-primary" role="alert">
						<p class="text-left">
							<b class="text-primary">Integração concluída!</b>
							Para que os clientes sejam importados e as mensagens de eventos sejam
							disparadas, é necessário selecionar pelo menos um Número de Disparo.
							Após selecionar e salvar, os clientes da sua loja
							<b>{{ integration.name }}</b>
							serão importados e anexados ao formulário
							<b>Clientes {{ integration.name }}</b>
							, que serão atualizados automaticamente em caso de novos pedidos na sua
							loja e poderão ser utilizados para campanhas personalizadas.
						</p>
						<p class="text-left mb-0">
							Caso mais de um número seja escolhido, os clientes importados serão
							distribuídos entre eles, dado prioridade para aqueles que estão
							<b>conectados</b>
							já que é necessário para a verificação e obtenção dos dados do contato.
						</p>
					</div>
				</div>

				<div class="col-12 text-left mt-5">
					<h5 class="text-secondary font-weight-bolder">Mensagens automáticas</h5>
				</div>

				<template v-for="[key, name] in Object.entries(patterns)">
					<div :key="`${key}-title`" class="col-12 col-md-3 mb-2 text-left">
						<span class="text-primary">{{ name }}</span>
					</div>
					<div
						:key="`${key}-input`"
						class="col-12 col-md-9 d-flex flex-column align-items-start"
					>
						<b-form-checkbox
							:value="true"
							:unchecked-value="false"
							class="mb-2"
							v-model="data.patterns[key].active"
						>
							Ativar
						</b-form-checkbox>

						<card-text-synonym
							:show-delete="false"
							class="w-100"
							:data="data.patterns[key]"
							@change="(val) => Object.assign(data, val)"
							:show-title="false"
							allow-attachments
							:macro-list="integrationMacroList"
						/>
					</div>
				</template>

				<div
					v-if="!loading.integration"
					class="col-12 col-md-6 offset-md-6 col-xl-5 offset-xl-7 row"
				>
					<div class="col-5">
						<router-link
							:to="{
								name: 'settings',
								params: { tab: 'integracoes' }
							}"
							v-slot="{ navigate, href }"
						>
							<a @click="navigate" :href="href" class="btn btn-outline-primary w-100">
								Voltar
							</a>
						</router-link>
					</div>

					<button type="submit" class="col-7 btn-primary btn w-100">Salvar</button>
				</div>
			</form>
		</div>
	</section>
</template>

<script>
import { BFormInput, BFormCheckbox, BFormGroup } from "bootstrap-vue";
import CardTextSynonym from "@/views/components/CardTextSynonym";
import { formatPhone, getStatus } from "@/api/functions";
import vSelect from "vue-select";
import api from "@/api";
import Loading from "@/views/components/Loading.vue";
import { integrationMacroList } from "@/api/constants";
import { integrations } from "@/views/pages/dashboard/settings/Integrations.vue";

const patterns = {
	new_order: "Novo pedido realizado",
	payed: "Pagamento confirmado",
	sended: "Pedido enviado"
};

export default {
	components: {
		BFormInput,
		BFormGroup,
		vSelect,
		BFormCheckbox,
		Loading,
		CardTextSynonym
	},
	data() {
		return {
			loading: {
				integration: false,
				save: false,
				phones: false
			},
			phones: [],
			data: null,
			patterns,
			firstIntegration: false,
			integrationMacroList
		};
	},
	computed: {
		/**
		 * Retorna o modelo da integração com os dados.
		 */
		integration() {
			return integrations.filter((e) => e.key === this.$route.params.type)[0];
		},
		/**
		 * Retorna o identificador do usuário da integração.
		 */
		integrationUser() {
			return this.data.config["store_id"] ?? null;
		},
		/**
		 * Retorna o token de acesso da integração.
		 */
		integrationToken() {
			const { type } = this.$route.params;
			if (type === "nuvemshop") return this.data.config["access_token"];
			if (type === "tray") return this.data.config["code"];
			return null;
		},
		/**
		 * Retorna o título do botão de habilitar/desabilitar a integração.
		 */
		toggleTitle() {
			if (this.data && this.data.status === "inactive") return "Ativar integração";
			return "Desativar integração";
		}
	},
	created() {
		const { type } = this.$route.params;
		this.getPhones();

		/**
		 * Cada integração exige alguns parâmetros obrigatórios, por isso é necessário validar sua existência abaixo.
		 */
		if (type === "nuvemshop" && this.verifyRequiredQuery(["code", "state"]))
			return this.callback("nuvemshop", this.$route.query);

		if (type === "tray" && this.verifyRequiredQuery(["code", "api_address"]))
			return this.callback("tray", this.$route.query);

		this.load(type);
	},
	methods: {
		formatPhone,
		/**
		 * Exibe as mensagens em um toast.
		 *
		 * @param {String} message
		 * @param {'danger'|'success'} type
		 * @returns {void}
		 */
		toast(message, type = "danger") {
			this.$bvToast.toast(message, {
				title: "Integrações",
				autoHideDelay: 2000,
				variant: type
			});
		},
		/**
		 * Faz o carregamento das informações de configuração da integração.
		 *
		 * @param {String} type Nome do serviço
		 * @returns {void}
		 */
		load(type) {
			this.loading.integration = true;
			api.get(`integrations/${type}`)
				.then((res) => {
					const { type, body, message } = res.data;

					if (type === "success") {
						if (body.config === null)
							return this.returnToIntegrationsOnError(
								"É necessário realizar a integração novamente"
							);

						this.data = this.prepareData(body);

						return;
					}
					this.returnToIntegrationsOnError(message);
				})
				.catch((err) => {
					let message = "Não foi possível sincronizar os dados de integração";

					if (err.response) message = err.response.data.message;

					this.returnToIntegrationsOnError(message);
				})
				.finally(() => (this.loading.integration = false));
		},
		/**
		 * Faz o carregamento dos números de disparo.
		 *
		 * @returns {void}
		 */
		getPhones() {
			this.loading.phones = true;
			api.get(`phones`)
				.then((res) => {
					const { type, body, message } = res.data;

					if (type === "success") return (this.phones = [...body]);
					this.toast(message);
				})
				.catch((err) => {
					let message = "Não foi possível carregar os números de disparo";

					if (err.response) message = err.response.data.message;

					this.toast(message);
				})
				.finally(() => (this.loading.phones = false));
		},
		/**
		 * Faz a integração de um serviço a partir de um callback.
		 *
		 * @param {String} type Nome do serviço
		 * @param {Object} config Configurações necessárias para obtenção do acesso
		 * @returns {void}
		 */
		callback(type, config) {
			this.loading.integration = true;

			api.post(`integrations/${type}/callback`, config)
				.then((res) => {
					const { type, body, message } = res.data;

					if (type == "success") {
						this.data = this.prepareData(body);

						return this.$router.push({
							name: "settings",
							params: this.$route.params
						});
					}

					this.returnToIntegrationsOnError(message);
				})
				.catch((err) => {
					let message = "Não foi possível sincronizar os dados de integração";

					if (err.response) message = err.response.data.message;
					this.returnToIntegrationsOnError(message);
				})
				.finally(() => (this.loading.integration = false));
		},
		/**
		 * Faz a verificação de preenchimento dos determinados parâmetros informados.
		 *
		 * @param {String[]} inputs
		 * @returns {Boolean}
		 */
		verifyRequiredQuery(inputs) {
			const query = this.$route.query;
			let result = true;

			for (let i = 0; i < inputs.length; i++) {
				const keys = Object.keys(query);
				const input = inputs[i];
				if (!(keys.includes(input) && query[input] != null)) result = false;
			}

			return result;
		},
		/**
		 * Retorna para a tela de integrações.
		 *
		 * @param {String} message
		 * @returns {void}
		 */
		returnToIntegrationsOnError(message) {
			this.toast(message);

			this.$router.push({
				name: "settings",
				params: { tab: "integracoes" }
			});
		},
		/**
		 * Faz a preparação dos dados recebidos do backend para manejo
		 *
		 * @param {Object} data
		 * @returns {Object}
		 */
		prepareData(data) {
			if (!data.patterns) {
				const obj = Object.fromEntries(
					Object.keys(patterns).map((e) => [
						e,
						{
							active: false,
							text: "Adicionar mensagem",
							attachments: [],
							synonyms: []
						}
					])
				);
				Object.assign(data, {
					patterns: obj
				});
			}

			const payload = { phones: [], all_phones: false, ...data };

			this.firstIntegration = payload.phones.length == 0 && !payload.all_phones;
			return payload;
		},
		/**
		 * Salva os dados de configuração da integração.
		 *
		 * @returns {void}
		 */
		save() {
			const type = this.$route.params.type;
			this.loading.save = true;

			api.put(`integrations/${type}`, this.data)
				.then((res) => {
					const { type, body, message } = res.data;

					if (type == "success") {
						this.toast(message, "success");
						return (this.data = this.prepareData(body));
					}

					this.toast(message);
				})
				.catch((err) => {
					let message = "Não foi possível salvar as configurações de integração";

					if (err.response) message = err.response.data.message;
					this.toast(message);
				})
				.finally(() => (this.loading.save = false));
		},
		/**
		 * Sincroniza os webhooks da integração.
		 *
		 * @returns {void}
		 */
		sync() {
			const type = this.$route.params.type;
			this.loading.save = true;

			api.post(`integrations/${type}/sync`, this.data)
				.then((res) => {
					const { type, message } = res.data;

					if (type == "success") return this.toast(message, "success");

					this.toast(message);
				})
				.catch((err) => {
					let message = "Não foi possível sincronizar os webhooks da integração";

					if (err.response) message = err.response.data.message;
					this.toast(message);
				})
				.finally(() => (this.loading.save = false));
		},

		/**
		 * Ativa/desativa a integração.
		 *
		 * @returns {void}
		 */
		toggle() {
			const type = this.$route.params.type;
			this.loading.save = true;

			if (!this.data) return;

			let method = "delete";

			if (this.data.status === "inactive") method = "patch";

			api[method](`integrations/${type}`)
				.then((res) => {
					const { type, message } = res.data;

					if (type == "success") {
						this.data.status = this.data.status === "inactive" ? "active" : "inactive";
						return this.toast(message, "success");
					}

					this.toast(message);
				})
				.catch((err) => {
					let message = "Não foi possível ativar/desativar a integração";

					if (err.response) message = err.response.data.message;
					this.toast(message);
				})
				.finally(() => (this.loading.save = false));
		},
		/**
		 * Prepara o texto de exibição do telefone.
		 *
		 * @param {Object} option
		 * @return {string}
		 */
		getLabelPhone(option) {
			let labels = [];

			if (option.phone) labels.push(formatPhone(option.phone));

			if (option.name) labels.push(`${option.name}`);

			if (option.canceled_at) labels.push(`Em cancelamento`);
			else if (option.status) labels.push(`${getStatus(option.status)}`);

			return labels.join(" - ");
		}
	}
};
</script>
