<template>
    <b-modal
        :no-close-on-backdrop="loading"
        id="paymentMethodModal"
        centered
        :title="title"
        body-class="position-unset"
        content-class="p-4 position-relative"
        footer-border-variant="light"
        header-border-variant="light"
        size="lg">
        <loading v-if="loading"/>

        <div class="d-flex">
            <div class="d-flex flex-column align-items-center justify-content-center mr-3" v-if="allow_credit">
                <button
                    @click="method = 'credit_card'"
                    :class="{'btn-primary': method === 'credit_card', 'btn-outline-primary': method !== 'credit_card'}"
                    class="rounded btn py-3 px-5"
                >
                    <font-awesome-icon icon="fa-solid fa-credit-card" size="xl"/>
                </button>
                <small>Cartão de Crédito</small>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center mr-3" v-if="allow_debit">
                <button
                    @click="method = 'debit_card'"
                    :class="{'btn-primary': method === 'debit_card', 'btn-outline-primary': method !== 'debit_card'}"
                    class="rounded btn py-3 px-5"
                >
                    <font-awesome-icon icon="fa-solid fa-credit-card" size="xl"/>
                </button>
                <small>Cartão de Débito</small>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center mr-3" v-if="allow_boleto">
                <button
                    @click="method = 'boleto'"
                    :class="{'btn-primary': method === 'boleto', 'btn-outline-primary': method !== 'boleto'}"
                    class="rounded btn py-3 px-5"
                >
                    <font-awesome-icon icon="fa-solid fa-barcode" size="xl"/>
                </button>
                <small>Boleto</small>
            </div>
        </div>

        <validation-observer ref="paymentMethodForm" tag="form" class="my-3">
            <section>
                <div class="row my-1">
                    <div class="col-6" v-if="['credit_card', 'debit_card'].includes(method)">
                        <p class="font-weight-bold">Informações do Cartão</p>
                        <section class="row">
                            <div class="col-6 py-2">
                                <validation-provider
                                    #default="{ failed }"
                                    rules="required"
                                >
                                    <input
                                        :class="`form-control ${failed && 'is-invalid'}`"
                                        type="text"
                                        class="form-control"
                                        placeholder="Titular"
                                        v-model="card.holder_name"
                                        name="holder_name"
                                        @input="removeAccents()"
                                    >
                                </validation-provider>
                            </div>
                            <div class="col-6 py-2">
                                <validation-provider
                                    #default="{ failed }"
                                    rules="min:14"
                                    v-mask="['###.###.###-##', '##.###.###/####-##']"
                                >
                                    <input
                                        :class="`form-control ${failed && 'is-invalid'}`"
                                        type="text"
                                        class="form-control"
                                        placeholder="CPF/CNPJ"
                                        v-model="card.holder_document"
                                        name="holder_document"
                                        required
                                    >
                                </validation-provider>
                            </div>
                            <div class="col-12 py-2">
                                <validation-provider
                                    #default="{ failed }"
                                    rules="required|min:16"
                                    v-mask="['#### #### #### ####', '#### ###### ####', '#### ###### #####']"
                                >
                                    <input
                                        :class="`form-control ${failed && 'is-invalid'}`"
                                        type="text"
                                        class="form-control"
                                        placeholder="Número do cartão"
                                        v-model="card.number"
                                        name="number"
                                    >
                                </validation-provider>
                            </div>
                            <div class="col-6 py-2">
                                <validation-provider
                                    #default="{ failed }"
                                    rules="required|min:3"
                                >
                                    <input
                                        :class="`form-control ${failed && 'is-invalid'}`"
                                        class="form-control"
                                        placeholder="CVV"
                                        v-mask="['###', '####']"
                                        v-model="card.cvv"
                                        name="cvv"
                                    >
                                </validation-provider>
                            </div>
                            <div class="col-6 py-2">
                                <div class="input-group row m-0">
                                    <validation-provider
                                        #default="{ failed }"
                                        rules="required|between:1,12|min:2"
                                        slim
                                    >
                                        <input
                                            :class="`form-control ${failed && 'is-invalid'}`"
                                            type="text"
                                            class="form-control"
                                            placeholder="Mês"
                                            v-mask="'##'"
                                            v-model="card.exp_month"
                                            name="exp_month"
                                        >
                                    </validation-provider>
                                    <validation-provider
                                        #default="{ failed }"
                                        :rules="`required|between:${moment().format('YY')},99|min:2`"
                                        slim
                                    >
                                        <input
                                            :class="`form-control ${failed && 'is-invalid'}`"
                                            type="text"
                                            class="form-control"
                                            placeholder="Ano"
                                            v-mask="'##'"
                                            v-model="card.exp_year"
                                            name="exp_year"
                                        >
                                    </validation-provider>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div :class="['credit_card', 'debit_card'].includes(method) ? 'col-6' : 'col-12'">
                        <p class="font-weight-bold">Endereço de cobrança</p>
                        <section class="row">
                            <div class="col-5 py-2">
                                <validation-provider
                                    #default="{ failed }"
                                    rules="required|min:9"
                                >
                                    <input
                                        :class="`form-control ${failed && 'is-invalid'}`"
                                        type="text"
                                        class="form-control"
                                        placeholder="CEP"
                                        v-mask="'#####-###'"
                                        v-model="card.billing_address.zip_code"
                                        name="zip_code"
                                    >
                                </validation-provider>
                            </div>
                            <div class="col-7 py-2">
                                <validation-provider
                                    #default="{ failed }"
                                    rules="required"
                                >
                                    <select
                                        :class="`form-control ${failed && 'is-invalid'}`"
                                        v-model="card.billing_address.country"
                                        name="country"
                                    >
                                        <option :value="null" hidden selected>País</option>
                                        <option
                                            v-for="country in Object.keys(countries)"
                                            :key="`state-option-${country}`"
                                            :value="country"
                                        >{{ countries[country] }}</option>
                                    </select>
                                </validation-provider>
                            </div>
                            <div class="col-4 py-2">
                                <validation-provider
                                    #default="{ failed }"
                                    rules="required"
                                >
                                    <select
                                        :class="`form-control ${failed && 'is-invalid'}`"
                                        v-model="card.billing_address.state"
                                        name="state"
                                    >
                                        <option :value="null" hidden selected>UF</option>
                                        <option
                                            v-for="state in Object.keys(states)"
                                            :key="`state-option-${state}`"
                                            :value="state"
                                        >{{ state }}</option>
                                    </select>
                                </validation-provider>
                            </div>
                            <div class="col-8 py-2">
                                <validation-provider
                                    #default="{ failed }"
                                    rules="required"
                                >
                                    <input
                                        :class="`form-control ${failed && 'is-invalid'}`"
                                        type="text"
                                        class="form-control"
                                        placeholder="Cidade"
                                        v-model="card.billing_address.city"
                                    >
                                </validation-provider>
                            </div>
                            <div class="col-12 py-2">
                                <validation-provider
                                    #default="{ failed }"
                                    rules="required"
                                >
                                    <input
                                        :class="`form-control ${failed && 'is-invalid'}`"
                                        type="text"
                                        class="form-control"
                                        placeholder="Bairro"
                                        name="neighborhood"
                                        v-model="card.billing_address.address.district"
                                    >
                                </validation-provider>
                            </div>
                            <div class="col-12 py-2">
                                <validation-provider
                                    #default="{ failed }"
                                    rules="required"
                                >
                                    <input
                                        :class="`form-control ${failed && 'is-invalid'}`"
                                        type="text"
                                        class="form-control"
                                        placeholder="Endereço"
                                        v-model="card.billing_address.address.street"
                                    >
                                </validation-provider>
                            </div>
                            <div class="col-4 py-2">
                                <validation-provider
                                    #default="{ failed }"
                                    rules="required"
                                >
                                    <input
                                        :class="`form-control ${failed && 'is-invalid'}`"
                                        type="number"
                                        class="form-control"
                                        placeholder="Número"
                                        v-model="card.billing_address.address.number"
                                    >
                                </validation-provider>
                            </div>
                            <div class="col-8 py-2">
                                <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Complemento"
                                    name="complement"
                                    v-model="card.billing_address.complement"
                                >
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </validation-observer>
        <template #modal-footer="{ ok, cancel }">
            <div class="d-flex flex-column justify-content-end">
                <div class="d-flex justify-content-end align-items-end">
                    <span class="mr-3">Total:</span>
                    <h3 class="text-blue fs-1 m-0">{{ defined_value ?? user.plan.price }}</h3>
                    <small v-if="!defined_value">/mês</small>
                </div>
                <div class="d-flex justify-content-end mt-3">
                    <button :disabled="loading" @click="cancel" class="btn btn-secondary mx-2">Cancelar</button>
                    <button :disabled="loading" @click="submit(ok)" class="btn btn-primary">{{defined_value ? 'Confirmar' : 'Salvar'}}</button>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, between } from "@validations";
import { states, countries } from "@/api/constants";
import axios from "axios";
import api from "@/api";

export default {
    components: {
        BModal,
        Loading,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            required,
            min,
            between,
            loading: false,
            method: 'credit_card',
            card: {
                number: null,
                holder_name: null,
                holder_document: null,
                exp_month: null,
                exp_year: null,
                cvv: null,
                billing_address: {
                    complement: null,
                    zip_code: null,
                    city: null,
                    state: null,
                    country: 'BR',
                    address: {
                        street: null,
                        number: null,
                        district: null,
                    }
                },
            },
            states,
            countries
        }
    },
    computed: {
        full_address() {
            const { street, number, district } = this.card.billing_address.address;
            const array = [number, street, district];
            return array.join(', ');
        }
    },
    props: {
        title: {
            type: String,
            default: 'Mudar forma de pagamento'
        },
        plan_id: {
            type: String,
            required: false
        },
        allow_credit: {
            type: Boolean,
            default: true
        },
        allow_debit: {
            type: Boolean,
            default: true
        },
        allow_boleto: {
            type: Boolean,
            default: true
        },
        payment_url: {
            type: String,
            default: '/purchases'
        },
        kind: {
            type: String,
            default: 'plan'
        },
        quantity: {
            type: Number,
            default: null
        },
        defined_value: {
            type: Number,
            default: null
        },
        coupon_code: {
            type: String,
            default: ''
        }
    },
    methods: {
        removeAccents() {
            this.card.holder_name = this.card.holder_name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        },
        toast(message, type = "danger") {
            this.$bvToast.toast(message, {
                title: 'Método de pagamento',
                autoHideDelay: 2000,
                variant: type
            })
        },
        getTokenCard() {
            const url = 'https://api.pagar.me/core/v5/tokens?appId=' + process.env.VUE_APP_PAGARME_PUBLIC_KEY;
            return axios.post(url, {
                type: 'card',
                card: {
                    number: this.card.number.replace(/\s/g, ''),
                    holder_name: this.card.holder_name,
                    holder_document: this.card.holder_document.replace(/\D/g, ''),
                    exp_month: this.card.exp_month,
                    exp_year: this.card.exp_year,
                    cvv: this.card.cvv,
                }
            });
        },
        async submit(closeHandle) {
            const validate = await this.$refs.paymentMethodForm.validate();
            if (!validate) return;
            this.loading = true;
            const address = {
                line_1: this.full_address,
                line_2: this.card.billing_address.complement,
                city: this.card.billing_address.city,
                state: this.card.billing_address.state,
                country: this.card.billing_address.country,
                zip_code: this.card.billing_address.zip_code.replace(/\D/g, ''),
            };

            if (this.method === 'credit_card' || this.method === 'debit_card') {
                this.getTokenCard().then(({ data }) => {
                    const card_token = data.id;

                    const messages = {
                        'Could not create credit card. The card verification failed.': 'Não foi possível criar o cartão. A verificação do cartão falhou.',
                    };

                    api.post(this.payment_url, {
                        card_token,
                        payment_method: this.method,
                        address,
                        kind : this.kind,
                        quantity: this.quantity,
                        defined_value: this.defined_value,
                        coupon_code: this.coupon_code
                    }).then(({ data }) => {
                        const body = data.body;
                        if (body && body.status === 'paid') {
                            this.toast('Pagamento realizado com sucesso!', 'success');
                            this.$store.dispatch('user/loadSession').then(() => {
                                this.$router.push({name: 'campaigns'});
                            });
                        } else if (body && body.status === 'pending') {
                            this.toast('Pagamento pendente, aguarde a confirmação do pagamento.', 'warning');
                        } else {
                            this.toast(messages[data.message] || 'Não foi possível realizar o pagamento.', 'danger');
                        }

                    })
                    .catch(error => {
                        this.toast(messages[error.message] || 'Não foi possível realizar o pagamento.', 'danger');
                    })
                    .finally(() => {
                        this.loading = false;
                    });
                }).catch(( response ) => {
                    this.loading = false;
                    console.log('errors =>', response)
                    if (response.data.hasOwnProperties('errors')) {
                        const errors = response.data.errors;
                        if (errors) {
                            Object.keys(errors).forEach(error => {
                                this.toast(errors[error]);
                            });
                        }
                    }

                });
            } else if (this.method === 'boleto') {
                api.post(this.payment_url, {
                    payment_method: this.method,
                    address,
                    kind: this.kind,
                    quantity: this.quantity,
                    defined_value: this.defined_value,
                    coupon_code: this.coupon_code
                }).then(({ data }) => {
                    const body = data.body;
                    if (body.status === "pending") {
                        const url = body.charges[0].last_transaction.pdf;
                        this.$emit('boleto', url);
                        this.toast('Pagamento pendente, aguarde a confirmação do pagamento.', 'warning');
                        this.$store.dispatch('user/loadSession')
                    } else {
                        this.toast('Pagamento não realizado, tente novamente.', 'danger');
                    }
                })
                .finally(() => {
                    this.loading = false;
                    closeHandle();
                });
            }
        }
    },
}
</script>
