<template>
    <section class="position-relative px-4">
        <loading v-if="loading.permissions || loading.groups"/>
        <div class="table-responsive" v-if="$role(['groups.get'])">
            <table class="table">
                <thead>
                <tr>
                    <th class="font-weight-light text-left text-blue" scope="col">Equipes</th>
                    <th class="font-weight-light text-secondary" scope="col">Membros</th>
                    <th class="font-weight-light text-blue" scope="col">Supervisor</th>
                    <th
                        v-for="permission in permissions"
                        :key="`th-${permission._id}`"
                        class="font-weight-light text-blue"
                        scope="col"
                        style="white-space: nowrap"
                    >
                        {{ permission.name }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="group in groups" :key="`tr-${group._id}`">
                    <td class="text-left" scope="row" style="white-space: nowrap;">
                        <p contenteditable>{{ group.name }}</p>
                    </td>
                    <td>{{ group.members_count }}</td>
                    <td classscope="row">
                        <input
                            v-model="group.is_supervisor"
                            :name="`group-${group._id}-supervisor`"
                            :value="true"
                            type="checkbox"
                        >
                    </td>
                    <td
                        v-for="permission in permissions"
                        :key="`td-${group._id}-${permission._id}`"
                    >
                        <input
                            v-model="group.permissions"
                            :name="`group-${group._id}-permissions`"
                            :value="permission._id"
                            type="checkbox"
                        >
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="d-flex justify-content-start my-5" v-if="$role(['groups.create'])">
            <h5
                v-b-modal.addGroupModal
                class="text-left text-secondary font-weight-bolder d-flex align-items-center"
                role="button"
            >
                <span class="circle-icon mr-3">+</span>
                Adicionar uma nova equipe
            </h5>
        </div>

        <div v-if="$role(['groups.update'])" class="row align-items-center justify-content-end">
            <div class="col-12 col-sm-6 col-md-2 d-flex mt-2">
                <button class="btn flex-grow-1 btn-primary" @click.prevent="handleUpdateGroups">Salvar</button>
            </div>
        </div>
        <add-group-modal @update="fetchGroups" v-if="$role(['groups.create'])"/>
    </section>
</template>

<script>
import AddGroupModal from '@/views/pages/dashboard/settings/components/AddGroupModal.vue'
import Loading from "@/views/components/Loading.vue";
import api from '@/api';

export default {
    components: {
        AddGroupModal,
        Loading
    },
    data() {
        return {
            groups: [],
            permissions: [],
            loading: {
                groups: false,
                permissions: false
            }
        }
    },
    created() {
        if(this.$role('groups.get')) {
            this.fetchGroups();
        }
        this.fetchPermissions();
    },
    methods: {
        toast(message, type = "danger") {
            this.$bvToast.toast(message, {
                title: 'Equipes',
                autoHideDelay: 2000,
                variant: type
            })
        },
        fetchGroups() {
            this.loading.groups = true;
            api
                .get('/groups')
                .then(response => {
                    const {type, body} = response.data;
                    if (type == 'success') {
                        this.groups = body;
                    }
                })
                .finally(() => this.loading.groups = false)
        },
        fetchPermissions() {
            this.loading.permissions = true;
            api
                .get('/permissions')
                .then(response => {
                    const {type, body} = response.data;
                    if (type == 'success') {
                        this.permissions = body;
                    }
                })
                .finally(() => this.loading.permissions = false)
        },
        /**
         * Atualiza a equipe.
         * @param {Object} group
         * @param {String} group._id
         */
        async updateGroup(group) {
            try {
                const {name, permissions, is_supervisor} = group;
                const {data: {type, message}} = await api.put(`/groups/${group._id}`, {name, permissions, is_supervisor});

                if (type == 'success') return this.toast(`Equipe ${group.name} atualizada com sucesso.`, 'success');
                this.toast(message);
            } catch (error) {
                if (error.response) {
                    return this.toast(error.response.data.message);
                }
                this.toast(`Não foi possível atualizar a equipe ${group.name}, tente novamente mais tarde.`);
            }
        },
        handleUpdateGroups() {
            this.loading.groups = true;

            Promise.all(this.groups.map(this.updateGroup))
                .finally(() => this.loading.groups = false);
        }
    }
}
</script>
