<template>
    <b-modal 
        id="transferPhoneNumberModal" 
        title="Transferir número" 
        centered 
        :no-close-on-backdrop="loading" 
        @show="getPhones"
        @hidden="$emit('input', null)"
    >
        <loading v-if="loading"/>
        <validation-observer ref="transferPhoneNumberForm">
            <form @submit.prevent>
                <p>Transferir os contatos do número <b>{{ value != null ? formatPhone(value.phone) : '(00) 00000-0000' }}</b> para:</p>
                <div class="form-group">
                    <validation-provider
                        #default="{ errors }"
                        name="percentage"
                        rules="integer|required">
                        <input
                            v-model="transferPercent"
                            class="form-control mb-2"
                            placeholder="Percentual de transferência"
                            min="1"
                            max="100"
                            type="number"
                            @input="transferPercent && transferPercent > 100 ? transferPercent = 100 : null; transferPercent && transferPercent < 1 ? transferPercent = 1 : null"
                            :class="{ 'is-invalid': errors[0] }"
                        >
                    </validation-provider>
                    <validation-provider
                        #default="{ errors }"
                        name="número de telefone"
                        rules="required"
                    >
                        <select v-model="phone_id" :class="`form-control ${errors.length > 0 && 'is-invalid'}`">
                            <option :value="null" selected hidden>Selecione o número</option>
                            <option v-if="phones.filter(e => e._id !== value._id).length === 0" disabled>Nenhum número disponível</option>
                            <option
                                v-for="(phone, i) in phones.filter(e => e._id !== value._id)"
                                :key="`phone-option-${i}`"
                                :value="phone._id"
                            >
                                {{ phone.name }} {{ formatPhone(phone.phone) }}
                            </option>
                        </select>
                        <small class="invalid-feedback">{{ errors[0] }}</small>
                    </validation-provider>
                </div>
            </form>
        </validation-observer>
        <template #modal-footer="{ cancel, close }">
            <div class="d-flex align-items-center jusitfy-content-end">
                <button :disabled="loading" @click="cancel" class="btn btn-secondary mx-2">Cancelar</button>
                <button :disabled="loading" @click.prevent="handleTransferPhoneNumber(close)" type="submit" class="btn btn-primary">Transferir</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { formatPhone } from "@/api/functions";
import api from "@/api";

export default {
    components: {
        BModal,
        Loading,
        ValidationProvider,
        ValidationObserver
    },
    props: ['value'],
    emits: ['input', 'update'],
    data() {
        return {
            required,
            phone_id: null, // APENAS EXEMPLO,
            transferPercent: null,
            loading: false,
            phones: []
        }
    },
    mounted() {
        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
            if(modalId === 'transferPhoneNumberModal') {
                this.phone_id = null;
            }
        })
    },
    created() {
        this.getPhones()
    },
    methods: {
        formatPhone,
        getPhones() {
            if(!this.loading) {
                this.loading = true;
                api.get('/phones', { all: 1 })
                .then(response => {
                    const { type, body } = response.data;
    
                    if(type === 'success') {
                        return this.phones = body;
                    }
                })
                .finally(() => this.loading = false);
            }
        },
        toast(message, type = "danger") {
            this.$root.$bvToast.toast(message, {
                title: 'Números de Telefone',
                autoHideDelay: 2000,
                variant: type
            })
        },
        handleTransferPhoneNumber(closeModalHandler) {
            this.$refs.transferPhoneNumberForm.validate()
            .then(success => {
                if(!success) return;

                this.loading = true;

                api.put(`/phones/${this.value._id}/transfer`, { phone_id: this.phone_id, percent: this.transferPercent })
                .then(response => {
                    const { type, message } = response.data;

                    if(type === 'success') {
                        this.toast(message, 'success');
                        this.$emit('update')
                        return closeModalHandler()
                    }
                    throw response;
                })
                .catch(() => {
                    this.toast('Não foi possível transferir contatos.', 'danger');
                })
                .finally(() => this.loading = false)
            })
        }
    }
}
</script>
