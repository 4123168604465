<template>
    <b-modal
        @show="() => start()"
        @hide="finish"
        :no-close-on-backdrop="loading"
        id="qrCodeModal"
        ref="qrCodeModalRef"
        centered
        title="Conectar-se"
    >
        <ol>
            <li>Abra o WhatsApp no seu celular.</li>
            <li>Toque em <strong>Mais opções</strong> ou <strong>Configurações</strong> e selecione <strong>Aparelhos conectados</strong>.</li>
            <li>Aponte seu celular para esta tela para capturar o código.</li>
        </ol>

        <div class="d-flex justify-content-center w-100">
            <img
                v-if="qrCode != null"
                width="285"
                height="285"
                class="img-thumbnail"
                :src="qrCode"
                alt="Aponte a câmera do seu celular para este QRCode para se conectar."
            >
            <span
                v-else
                role="button"
                class="img-thumbnail d-flex flex-column align-items-center justify-content-center reload-qrcode"
                @click="() => start(true)"
            >
                <font-awesome-icon
                    icon="fa-solid fa-rotate"
                    class="bg-secondary text-white"
                    :spin="loading"
                />
                <p v-if="qrCode == null && !loading" class="m-0 w-75 text-center mt-3">Clique aqui para recarregar o QRCode.</p>
            </span>
        </div>

        <template #modal-footer="{ cancel }">
            <div class="d-flex align-items-center jusitfy-content-end">
                <button :disabled="loading" @click="cancel" class="btn btn-secondary mx-2">Cancelar</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import api from "@/api";

const QRCODE_UPDATE_TIMEOUT = 18000;
const QRCODE_MAX_ATTEMPTS = 3;

export default {
    components: {
        BModal,
    },
    props: {
        phoneId: {
            type: String
        }
    },
    data() {
        return {
            loading: false,
            qrCode: null,
            timeout: null,
            attempts: 0
        }
    },
    emits: ['connected'],
    methods: {
        getQrCode() {
            clearTimeout(this.timeout);
            this.loading = true;

            if(this.attempts >= QRCODE_MAX_ATTEMPTS) {
                this.loading = false
                return this.qrCode = null
            }

            api.get(`/phones/${this.phoneId}/qr-code`)
            .then(response => {
                const { body, message } = response.data;

                if(response.status == 200) {
                    this.qrCode = body;
                    this.attempts += 1;
                    return this.timeout = setTimeout(this.getQrCode, QRCODE_UPDATE_TIMEOUT);
                }

                if(response.status == 201) {
                    this.$bvToast.toast('Telefone conectado com sucesso.', {title: 'Conectar', variant: 'success'});
                    this.$emit('connected', this.phoneId);
                    return this.$refs['qrCodeModalRef'].hide();
                }

                throw message;
            })
            .catch(err => {
                console.log(err);
                this.qrCode = false;
            })
            .finally(() => this.loading = false);
        },
        start(restart = false) {
            if(restart && !(this.qrCode == null && !this.loading))  return;

            this.attempts = 0;
            this.qrCode = null;
            this.getQrCode()
        },
        finish() {
            clearTimeout(this.timeout);
            this.qrCode = null;
            this.attempts = 0;
        }
    }
}
</script>

<style lang="scss" scoped>
    .reload-qrcode {
        aspect-ratio: 1;
        width: 100%;
        height: 100%;
        max-height: 285px;
        max-width: 285px;

        .fa-rotate {
            width: 100%;
            height: 100%;
            max-width: 40px;
            max-height: 40px;
            border-radius: 100%;
            padding: 2rem;
        }
    }
</style>
