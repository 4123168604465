<template>
    <b-card no-body>
        <b-card-body class="text-center position-relative overflow-hidden">
            <section class="position-relative px-4">
                <common-table-custom v-if="$role(['groups.get'])" :loading="loading.groups" v-bind="groupsTable">
                    <template v-slot:custom="item">
                        <font-awesome-icon
                            class="mx-1 text-secondary"
                            icon="fa-solid fa-trash-can"
                            role="button"
                            size="sm"
                            style="outline: none"
                            @click="handleDeleteGroup(item.data)"
                        />
                    </template>

                </common-table-custom>

                <hr class="my-4 line"/>

                <div v-if="$role(['users.get'])" class="row flex-wrap align-items-end">
                    <div class="col-9 col-lg-8">
                        <search-input :modelValue="usersFilters.search"
                                      @update:modelValue="(value) => usersFilters.search = value"/>
                    </div>

                    <div class="col-3 col-lg-2">
                        <select v-model="usersFilters.order" class="form-control my-1">
                            <option selected value="null">Ordenar por</option>
                            <option value="name">Pessoas</option>
                            <option value="group">Equipe</option>
                        </select>
                    </div>

                    <div class="col d-flex">
                        <button
                            class="btn btn-primary font-weight-bold my-1 flex-grow-1"
                            @click.prevent="fetchUsers"
                        >
                            Filtrar
                        </button>
                    </div>
                </div>

                <div v-if="$role(['users.create'])" class="d-flex justify-content-start my-5">
                    <h5
                        v-b-modal.addUserModal
                        class="text-left text-secondary font-weight-bolder d-flex align-items-center"
                        role="button"
                        v-if="plan.used_agents < plan.max_agents"
                    >
                        <span class="circle-icon mr-3">+</span>
                        Adicionar uma nova pessoa
                    </h5>
                    <h5 class="text-left text-secondary font-weight-bolder" v-else>Máximo de agentes atingido</h5>
                </div>

                <common-table-custom :loading="loading.users" v-bind="usersTable">
                    <template v-slot:custom="item">
                        <input v-model="selectedUsers" :value="item.data" name="selectedUsers" type="checkbox"/>
                    </template>
                </common-table-custom>
            </section>
        </b-card-body>
        <b-card-footer v-if="selectedUsers.length > 0"
                       class="bg-primary d-flex flex-column flex-sm-row justify-content-between align-items-center px-4">
            <p class="m-0 text-light font-weight-regular fs-4">{{ selectedUsers.length }} pessoa selecionada</p>
            <div>
                <font-awesome-icon
                    v-if="$role(['users.update'])"
                    v-b-modal.editUserModal
                    :hidden="loading.users"
                    class="my-1 text-light"
                    icon="fa-solid fa-pen-to-square"
                    role="button"
                    size="lg"
                    style="outline: none"
                />
                <font-awesome-icon
                    v-if="$role(['users.delete'])"
                    :hidden="loading.users"
                    class="my-1 ml-4 text-light"
                    icon="fa-solid fa-trash-can"
                    role="button"
                    size="lg"
                    style="outline: none"
                    @click="handleDeleteUsers"
                />
            </div>
        </b-card-footer>

        <add-user-modal v-if="$role(['users.create'])" @update="fetchUsers(); fetchGroups()"/>
        <edit-users-modal v-if="$role(['users.update'])" :value="selectedUsers"/>
    </b-card>
</template>

<script>
import {BCard, BCardBody, BCardFooter} from "bootstrap-vue";
import CommonTableCustom from "@/views/components/CommonTableCustom.vue";
import SearchInput from "@/views/components/SearchInput.vue";
import {groupsTableModel, usersTableModel, usersFiltersModel} from "@/views/pages/dashboard/settings/constants.js";
import AddUserModal from "@/views/pages/dashboard/settings/components/AddUserModal.vue";
import EditUsersModal from "@/views/pages/dashboard/settings/components/EditUsersModal.vue";
import api from '@/api';

export default {
    components: {
        CommonTableCustom,
        SearchInput,
        AddUserModal,
        EditUsersModal,
        BCard,
        BCardBody,
        BCardFooter,
    },
    data() {
        return {
            groupsTable: groupsTableModel,
            usersTable: usersTableModel,
            usersFilters: usersFiltersModel,
            selectedUsers: [],
            editGroup: null,
            loading: {
                groups: false,
                users: false
            }
        }
    },
    computed: {
        plan() {
            return this.$store.getters['user/getPlan'];
        }
    },
    created() {
        if (this.$role(['groups.get'])) {
            this.fetchGroups();
        }
        if (this.$role(['users.get'])) {
            this.fetchUsers();
        }
    },
    methods: {
        toast(message, type = "danger") {
            this.$bvToast.toast(message, {
                title: 'Usuários',
                autoHideDelay: 2000,
                variant: type
            })
        },
        fetchGroups() {
            this.loading.groups = true;
            api
                .get('/groups', {params: {only: ['_id', 'name', 'members_count']}})
                .then(response => {
                    const {type, body} = response.data;
                    if (type === 'success') {
                        this.groupsTable.items = body;
                    }
                })
                .finally(() => this.loading.groups = false);
        },
        fetchUsers() {
            this.selectedUsers = [];
            this.loading.users = true;

            const params = {
                only: ['_id', 'name', 'group', 'group_id', 'email'],
                search: this.usersFilters.search,
                order: this.usersFilters.order == 'null' ? -1 : 1,
                order_column: this.usersFilters.order == 'null' ? 'created_at' : this.usersFilters.order
            }

            api
                .get('/users', {params})
                .then(response => {
                    const {type, body} = response.data;
                    if (type === 'success') {
                        this.usersTable.items = body;
                    }
                })
                .finally(() => this.loading.users = false);
        },
        /**
         * Remove o usuário.
         * @param {Object} user
         * @param {String} user._id
         */
        async asyncDeleteUser(user) {
            try {
                const response = await api.delete(`/users/${user._id}`);

                const {type, message} = response.data;
                if (type === 'success') {
                    this.usersTable.items = this.usersTable.items.filter(v => v._id != user._id);
                    this.selectedUsers = this.selectedUsers.filter(v => v._id != user._id);
                    return this.toast(`${user.name} removido com sucesso.`, 'success');
                }
                this.toast(message);
            } catch (error) {
                if (error.response) {
                    return this.toast(error.response.data.message);
                }
                this.toast("Não foi possível remover o usuário, tente novamente mais tarde.")
                console.log(error);
            }
        },
        handleDeleteUsers() {
            this.loading.users = true;

            Promise.all(this.selectedUsers.map(async user => this.asyncDeleteUser(user)))
                .finally(() => this.loading.users = false)
        },
        handleDeleteGroup(group) {
            this.$swal({
                title: 'Remover Equipe',
                text: 'Tem certeza que deseja remover essa equipe?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Remover',
                confirmButtonColor: "#317f98",
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    return await api.delete(`/groups/${group._id}`);
                }
            })
                .then(result => {
                    if (!result.isConfirmed) return;

                    const {type, message} = result.value.data;

                    if (type === 'success') this.fetchGroups();

                    this.toast(message, type === 'success' ? 'success' : 'danger');
                })
                .catch(error => {
                    if (error.response) {
                        return this.toast(error.response.data.message);
                    }
                    this.toast('Houve um erro ao remover equipe, tente novamente em alguns instantes.');
                })
        }
    }
}
</script>
