<template>
    <div class="input-group flex-nowrap my-1">
        <label
            class="input-group-text"
            :for="id"
        >
            <feather-icon size="20" icon="SearchIcon" />
        </label>

        <input
            type="text"
            class="form-control"
            :placeholder="placeholder"
            :aria-label="placeholder"
            :id="id"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
        >
    </div>
</template>

<script>
export default {
    props: {
        placeholder: {
            type: String,
            default: () => 'Buscar'
        },
        id: {
            type: String,
            default: () => `search-input-${Date.now().toString()}`
        },
        modelValue: {
            type: String
        }
    },
    emits: ["update:modelValue"]
}
</script>

<style lang="scss" scoped>
    .input-group{
        .input-group-text{
            border:none;
            background-color: transparent;
            color: #999999;
        }

        .form-control{
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            border: none;
            border-bottom: 1px solid #EEEEEE;
            &:focus{
                box-shadow: none;
            }
        }
    }
</style>
