<template>
    <b-modal
        no-close-on-backdrop
        id="addPhoneModal"
        centered
        :title="form.id ? 'Editar Nome do Número de disparo' : 'Adicionar Número de Disparo'"
        @hide="reset">
        <loading v-if="loading"/>
        <validation-observer ref="addPhoneForm">
            <form>
                <div class="form-group">
                    <validation-provider
                        #default="{ errors }"
                        name="nome"
                        rules="required"
                    >
                        <label for="add-group-name">Nome</label>
                        <input
                            v-model="form.name"
                            :class="`form-control ${errors.length > 0 && 'is-invalid'}`"
                            required
                            type="text"
                            class="form-control"
                            placeholder="Nome">
                        <small class="invalid-feedback">{{ errors[0] }}</small>
                    </validation-provider>
                    <validation-provider
                        #default="{ errors }"
                        name="número"
                        rules="required"  
                    >
                        <label for="add-group-name" class="mt-2">Número</label>
                        <input
                            v-model="form.phone"
                            :class="`form-control ${errors.length > 0 && 'is-invalid'}`"
                            required
                            type="text"
                            class="form-control"
                            v-mask="['+## (##) #####-####', '+## (##) ####-####']"
                            placeholder="+00 (00) 00000-0000">
                        <small class="invalid-feedback">{{ errors[0] }}</small>
                    </validation-provider>
                </div>
            </form>
        </validation-observer>
        <template #modal-footer="{ cancel, ok }">
            <div class="d-flex align-items-center jusitfy-content-end">
                <button :disabled="loading" @click="cancel" class="btn btn-secondary mx-2">Cancelar</button>
                <button :disabled="loading" @click="() => handleAddPhone(ok)" class="btn btn-primary">{{ form.id ? 'Editar' : 'Adicionar' }}</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import api from '@/api';

export default {
    components: {
        BModal,
        Loading,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            required,
            form: {
                id: null,
                phone: null,
                name: null
            },
            loading: false,
        }
    },
    props: {
        phone_edit: {
            type: Object,
            default: null
        }
    },
    emits: ['update', 'close'],
    watch: {
        phone_edit: {
            handler(val) {
                if(val) {
                    this.form = {
                        id: val._id,
                        phone: val.phone,
                        name: val.name
                    }
                }
            },
            immediate: true
        }
    },
    methods: {
        toast(message, type = "danger") {
            this.$bvToast.toast(message, {
                title: 'Números de Disparos',
                autoHideDelay: 2000,
                variant: type
            })
        },
        reset() {
            this.form = {
                id: null,
                phone: null,
                name: null
            };
            this.$refs.addPhoneForm.reset();
            this.$emit('close');
        },
        handleAddPhone(closeHandler) {
            this.$refs.addPhoneForm.validate()
            .then(success => {
                if(!success) return;
                this.loading = true;

                const payload = {
                    id: this.form.id,
                    phone: this.form.phone.replaceAll(/[^0-9]/g, ''),
                    name: this.form.name
                };

                const method = this.form.id ? 'put' : 'post';
                const path = this.form.id ? `${this.form.id}` : '';

                api[method](`/phones/${path}`, payload)
                .then(response => {
                    const { type, message } = response.data;

                    if(type === 'success') {
                        this.toast(message, 'success');
                        this.$emit('update');
                        return closeHandler();
                    }

                    this.toast(message);
                })
                .catch(error => {
                    if(error.response) {
                        return this.toast(error.response.data.message);
                    }
                    this.toast(`Não foi possível ${this.form.id ? 'editar' : 'adicionar'} o número, tente novamente em alguns minutos.`)
                })
                .finally(() => this.loading = false);
            })
        },
    },
}
</script>
