<template>
    <b-modal no-close-on-backdrop v-model="visible" id="editUserModal" size="lg" centered title="Atualizar Pessoas">
        <loading v-if="loading.status"/>

        <div v-for="(user, index) in users" :key="`row-user-edit-${index}`" class="row">
            <div class="form-group col-12 col-md-4">
                <input v-model="user.name" required placeholder="Nome" type="text" class="form-control">
            </div>

            <div class="form-group col-12 col-md-4">
                <input v-model="user.email" required placeholder="Email" type="text" class="form-control">
            </div>

            <div class="form-group col-12 col-md-4 d-flex align-items-center">
                <v-select
                    :options="groups"
                    placeholder="Equipe"
                    :selectable="(option) => !groups.includes(option._id)"
                    :get-option-label="(option) => option.name"
                    :reduce="(option) => option._id"
                    :required="!user.group_id"
                    v-model="user.group_id"
                    :searchable="false"
                    :clearable="false"
                    :loading="loading.groups"
                    class="no-search flex-grow-1"
                >
                    <span slot="no-options">Desculpe, não há opções correspondentes.</span>
                </v-select>

                <font-awesome-icon
                    role="button"
                    @click="handleUpdateUser(user)"
                    class="text-secondary ml-3"
                    icon="fa-solid fa-save"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Atualizar"
                />
            </div>
        </div>
        <template #modal-footer="{ cancel }">
            <div class="d-flex align-items-center jusitfy-content-end">
                <button :disabled="loading.status" @click="cancel" class="btn btn-secondary mx-2">Cancelar</button>
                <button :disabled="loading.status" @click="handleUpdateAllUsers" class="btn btn-primary">Atualizar Todos</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import vSelect from "vue-select";
import api from '@/api';

export default {
    components: {
        BModal,
        Loading,
        vSelect
    },
    props: {
        value: {
            type: Array
        }
    },
    data() {
        return {
            users: [],
            groups: [],
            loading: {
                groups: false,
                status: false
            },
            visible: false
        }
    },
    created() {
        this.fetchGroups();
    },
    methods: {
        toast(message, type = "danger") {
            this.$bvToast.toast(message, {
                title: 'Usuários',
                autoHideDelay: 2000,
                variant: type
            })
        },
        /**
         * Atualiza os usuários selecionados no elemento pai.
         */
        updateParentData(newUser) {
            const data = this.$props.value;
            const { name, _id, email } = this.groups.find(v => v._id === newUser.group_id);
            const index = data.findIndex(v => v._id === newUser._id)
            this.$set(this.$props.value[index], 'name', newUser.name)
            this.$set(this.$props.value[index], 'group_id', newUser.group_id)
            this.$set(this.$props.value[index], 'email', newUser.email)
            this.$set(this.$props.value[index], 'group', { ...data, name, _id, email })
        },
        async asyncUpdateUser(user) {
            try {
                const { name, group_id, email } = user;

                const response = await api.put(`/users/${user._id}`, { name, group_id, email });

                const { type, message } = response.data;
                if(type === 'success') {
                    this.updateParentData(user);
                    return this.toast(`${user.name} atualizado com sucesso.`, 'success');
                }
                this.toast(message);
            } catch(error) {
                if(error.response) {
                    return this.toast(error.response.data.message);
                }
                this.toast("Não foi possível atualizar o usuário, tente novamente mais tarde.")
                console.log(error);
            }
        },
        handleUpdateUser(user) {
            this.loading.status = true;

            this
            .asyncUpdateUser(user)
            .finally(() => this.loading.status = false);
        },
        handleUpdateAllUsers() {
            this.loading.status = true;

            Promise.all(this.users.map(async user => {
                return this.asyncUpdateUser(user);
            }))
            .finally(() => this.loading.status = false);
        },
        fetchGroups() {
            this.loading.groups = true;
            api
            .get('/groups', { params: { only: ['_id', 'name'], all: 1 }})
            .then(response => {
                const { type, body } = response.data;
                if(type === 'success') {
                    this.groups = body;
                }
            })
            .finally(() => this.loading.groups = false);
        }
    },
    watch: {
        visible(value) {
            if(value) {
                // TÉCNICA PARA QUE O DATA DO ELEMENTO PAI NÃO SEJA ALTERADO.
                this.users = JSON.parse(JSON.stringify(this.$props.value));
            }
        }
    }
}
</script>
