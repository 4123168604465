<template>
    <b-modal no-close-on-backdrop id="addGroupModal" v-model="visible" centered title="Adicionar Equipe">
        <loading v-if="loading.status"/>
        <validation-observer ref="addGroupForm">
            <form>
                <div class="form-group">
                    <validation-provider
                        #default="{ errors }"
                        name="nome"
                        rules="required"
                    >
                        <label for="add-group-name">Nome</label>
                        <input
                            v-model="newGroup.name"
                            :class="`form-control ${errors.length > 0 && 'is-invalid'}`"
                            required
                            type="text"
                            class="form-control"
                            id="add-group-name"
                        >
                        <small class="invalid-feedback">{{ errors[0] }}</small>
                    </validation-provider>
                </div>
                <div class="form-group">
                    <label for="add-user-email">Permissões</label>
                    <v-select
                        :options="permissions"
                        multiple
                        placeholder="Selecione as permissões dessa equipe"
                        :selectable="(option) => !newGroup.permissions.includes(option._id)"
                        :get-option-label="(option) => option.name"
                        :reduce="(option) => option._id"
                        v-model="newGroup.permissions"
                        class="flex-grow-1"
                        :loading="loading.permissions"
                    >
                        <span slot="no-options">Desculpe, não há opções correspondentes.</span>
                    </v-select>

                    <small>Permissões rápidas:</small>
                    <div class="d-flex flex-wrap">
                        <span @click="selectPreDefinedPermissions('clear')" role="button" class="badge badge-danger mr-2">Limpar</span>
                        <span @click="selectPreDefinedPermissions('all')" role="button" class="badge badge-primary mr-2">Todos</span>
                        <span @click="selectPreDefinedPermissions('supervisor')" role="button" class="badge badge-secondary mr-2">Supervisor</span>
                        <span @click="selectPreDefinedPermissions('attendance')" role="button" class="badge badge-secondary mr-2">Atendente</span>
                        <span @click="selectPreDefinedPermissions('manager')" role="button" class="badge badge-secondary mr-2">Gerente</span>
                        <span @click="selectPreDefinedPermissions('crisis')" role="button" class="badge badge-secondary mr-2">Crise</span>
                    </div>
                </div>
                <b-form-checkbox
                    v-model="newGroup.is_supervisor"
                    name="add-group-is-supervisor"
                    :value="true"
                    :unchecked-value="false"
                    switch
                >
                    Supervisor
                </b-form-checkbox>
            </form>
        </validation-observer>
        <template #modal-footer="{ cancel }">
            <div class="d-flex align-items-center jusitfy-content-end">
                <button :disabled="loading.status" @click="cancel" class="btn btn-secondary mx-2">Cancelar</button>
                <button :disabled="loading.status" @click="handleAddGroup" class="btn btn-primary">Adicionar</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { BFormCheckbox, BModal } from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import { preDefinedPermissions } from "@/views/pages/dashboard/settings/constants";
import api from '@/api';

export default {
    components: {
        BModal,
        Loading,
        vSelect,
        ValidationProvider,
        ValidationObserver,
        BFormCheckbox
    },
    data() {
        return {
            required,
            newGroup: {
                name: null,
                permissions: [],
                is_supervisor: false
            },
            loading: {
                permissions: false,
                status: false
            },
            permissions: [],
            visible: false
        }
    },
    emits: ['update'],
    created() {
        this.fetchPermissions()
    },
    methods: {
        toast(message, type = "danger") {
            this.$bvToast.toast(message, {
                title: 'Equipes',
                autoHideDelay: 2000,
                variant: type
            })
        },
        resetForm() {
            this.newGroup = {
                name: null,
                permissions: []
            };
            this.$refs.addGroupForm.reset();
        },
        handleAddGroup() {
            this.$refs.addGroupForm.validate()
            .then(success => {
                if(!success) return;
                this.loading.status = true;

                api
                .post('/groups', this.newGroup)
                .then(response => {
                    const { type, message, errors } = response.data;

                    if(type == 'success') {
                        this.toast(message, 'success');
                        this.$emit('update');
                        return this.resetForm();
                    }

                    if(errors.length > 0) {
                        const error = errors.find(v => String(v).includes('name'));
                        if(error) return this.$refs.addGroupForm.setErrors({ 'name': [error] });
                    }

                    this.toast(message);
                })
                .catch(error => {
                    if(error.response) {
                        return this.toast(error.response.data.message);
                    }
                    this.toast("Não foi possível adicionar equipe, tente novamente em alguns minutos.")
                })
                .finally(() => this.loading.status = false);
            })
        },
        fetchPermissions() {
            this.loading.permissions = true;
            api
            .get('/permissions')
            .then(response => {
                const { type, body } = response.data;

                if(type == 'success') {
                    this.permissions = body;
                }
            })
            .finally(() => this.loading.permissions = false);
        },
        selectPreDefinedPermissions(name) {
            if(name == 'all') {
                return this.newGroup.permissions = this.permissions.map(perm => perm._id);
            }
            
            if(name == 'clear') {
                return this.newGroup.permissions = [];
            }

            const labels = preDefinedPermissions[name];
            const data = this.permissions.filter(perm => labels.includes(perm.label)).map(perm => perm._id);
            this.newGroup.permissions = data;
        }
    },
    watch: {
        visible(value) {
            if(!value) this.resetForm();
        }
    }
}
</script>
