<template>
	<b-modal
		no-close-on-backdrop
		id="importContactsModal"
		centered
		:title="title"
		@hide="reset"
		@show="load"
	>
		<loading v-if="loading.submit" />
		<validation-observer ref="importContactsForm">
			<form>
				<div class="form-group">
					<validation-provider #default="{ errors }" name="formulario" rules="required">
						<label for="add-form-input">
							Formulário
							<font-awesome-icon
								icon="fa-solid fa-info-circle"
								v-b-tooltip="
									'Formulário para onde os contatos serão importados, só poderão ser selecionados formulários que possuem Nome Completo e Telefone como campos obrigatórios. Caso nenhum formulário seja escolhido, o formulário padrão será utilizado.'
								"
							/>
						</label>
						<v-select
							v-model="form.form_id"
							:clearable="true"
							id="add-form-input"
							:get-option-label="(option) => option.name"
							:class="`${errors.length > 0 && 'is-invalid'}`"
							:options="forms"
							:reduce="(option) => option._id"
							:loading="loading.forms"
							:selectable="(option) => option._id != form.form_id"
							class="w-100"
							placeholder="Selecione um formulário"
						>
							<span slot="no-options">Desculpe, não há opções correspondentes.</span>
						</v-select>
						
						<small class="invalid-feedback">{{ errors[0] ?? '' }}</small>
					</validation-provider>
				</div>

				<div class="form-group">
					<label for="add-mark-input">
						Marcador
						<font-awesome-icon
							icon="fa-solid fa-info-circle"
							v-b-tooltip="'Marcador ao qual os contatos serão atrelados.'"
						/>
					</label>
					<v-select
						v-model="form.mark_id"
						:clearable="true"
						id="add-mark-input"
						:get-option-label="(option) => option.name"
						:options="marks"
						:reduce="(option) => option._id"
						:selectable="(option) => option._id != form.mark_id"
						:loading="loading.marks"
						class="w-100"
						placeholder="Selecione um marcador"
					>
						<span slot="no-options">Desculpe, não há opções correspondentes.</span>
					</v-select>
				</div>

				<div class="form-group">
					<validation-provider
						#default="{ errors }"
						name="numero de disparo"
						rules="required"
					>
						<label for="add-phone-input">
							Número de Disparo

							<font-awesome-icon
								icon="fa-solid fa-info-circle"
								v-b-tooltip="
									'Número de disparo aos quais o contato será atrelado, recomenda-se manter o número original de importação. Esse número PRECISA estar conectado durante toda a importação'
								"
							/>
						</label>
						<v-select
							v-model="form.phone_id"
							:class="`${errors.length > 0 && 'is-invalid'}`"
							:clearable="true"
							id="add-phone-input"
							:get-option-label="
								(option) => `${formatPhone(option.phone)} (${option.name})`
							"
							:options="phones"
							:reduce="(option) => option._id"
							:selectable="(option) => option._id != form.phone_id"
							:loading="loading.phones"
							class="w-100"
							placeholder="Selecione um número de disparo"
						>
							<span slot="no-options">Desculpe, não há opções correspondentes.</span>
						</v-select>

						<small class="invalid-feedback">{{ errors[0] ?? '' }}</small>
					</validation-provider>
				</div>

				<div class="form-group">
					<validation-provider ref="typeProvider" #default="{ errors }">
						<label for="add-type-input">
							Tipos
							<font-awesome-icon
								icon="fa-solid fa-info-circle"
								v-b-tooltip="
									'Contatos importa todos os seus contatos que tenham conta no Whatsapp somado aos contatos que participam de grupos com o seu número; Chat importa todos contatos aos quais você ja manteve uma conversa.'
								"
							/>
						</label>
						<b-form-checkbox-group
							:class="{ 'is-invalid': errors.length > 0 }"
							v-model="form.types"
							name="tipo"
							id="add-type-input"
						>
							<b-form-checkbox value="contacts">Contatos</b-form-checkbox>
							<b-form-checkbox value="chats">Chats</b-form-checkbox>
						</b-form-checkbox-group>

						<small class="invalid-feedback">{{ errors[0] ?? '' }}</small>
					</validation-provider>
				</div>

				<!-- <label>Extras</label>

				<div class="form-group">
					<b-form-checkbox v-model="form.import_products" :value="true">
						Importar catálogo
						<font-awesome-icon
							icon="fa-solid fa-info-circle"
							v-b-tooltip="'Os itens do catálogo serão salvos como produtos.'"
						/>
					</b-form-checkbox>
				</div>

				<div class="form-group">
					<b-form-checkbox v-model="form.import_answers" :value="true">
						Importar respostas rápidas
						<font-awesome-icon
							icon="fa-solid fa-info-circle"
							v-b-tooltip="
								'As respostas rápidas serão convertidas em respostas automáticas.'
							"
						/>
					</b-form-checkbox>
				</div> -->

				<small>
					<b>Observações:</b>
					A importação dos dados ocorrerá em segundo plano, permitindo que você continue
					utilizando o sistema como de costume. Assim que o processo for concluído, você
					receberá uma notificação informando que a importação foi finalizada com sucesso.
				</small>
			</form>
		</validation-observer>
		<template #modal-footer="{ cancel, ok }">
			<div class="d-flex align-items-center jusitfy-content-end">
				<button :disabled="loading.submit" @click="cancel" class="btn btn-secondary mx-2">
					Cancelar
				</button>
				<button
					:disabled="loading.submit"
					@click="() => handleImportContacts(ok)"
					class="btn btn-primary"
				>
					Importar
				</button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import { BModal, BFormCheckboxGroup, BFormCheckbox } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VSelect from "vue-select";

import Loading from "@/views/components/Loading.vue";
import { required } from "@validations";
import api from "@/api";
import { formatPhone } from "@/api/functions";

export default {
	components: {
		BModal,
		Loading,
		ValidationProvider,
		ValidationObserver,
		BFormCheckboxGroup,
		BFormCheckbox,
		VSelect
	},
	data() {
		return {
			required,
			form: {
				phone_id: null,
				mark_id: null,
				form_id: null,
				contact_phone_id: null,
				import_products: false,
				import_answers: false,
				types: ["contacts"]
			},
			forms: [],
			marks: [],
			phones: [],
			loading: {
				forms: false,
				marks: false,
				phones: false,
				submit: false
			}
		};
	},
	props: {
		phone: {
			type: Object,
			default: null
		}
	},
	emits: ["update", "close"],
	watch: {
		"$props.phone": {
			handler(val) {
				if (val && val._id && val.name) {
					this.form = {
						mark_id: null,
						form_id: null,
						import_products: false,
						import_answers: false,
						phone_id: val._id,
						types: ["contacts"]
					};
				}
			},
			immediate: true
		}
	},
	computed: {
		title() {
			if (!this.phone) return "Importar contatos";

			const number = formatPhone(this.phone.phone);
			return `Importar contatos de ${number}`;
		}
	},
	methods: {
		formatPhone,
		toast(message, type = "danger") {
			this.$bvToast.toast(message, {
				title: "Importar Contatos",
				autoHideDelay: 2000,
				variant: type
			});
		},
		load() {
			const params = { all: 1, order: "asc", order_column: "name", exclude_complex: 1 };

			if (this.forms.length === 0) {
				this.loading.forms = true;
				api.get(`/forms`, { params })
					.then((res) => {
						const { type, body } = res.data;
						if (type === "success") this.forms = body;
					})
					.finally(() => (this.loading.forms = false));
			}

			if (this.marks.length === 0) {
				this.loading.marks = true;
				api.get(`/marks`, { params })
					.then((res) => {
						const { type, body } = res.data;
						if (type === "success") this.marks = body;
					})
					.finally(() => (this.loading.marks = false));
			}

			if (this.phones.length === 0) {
				this.loading.phones = true;
				api.get(`/phones`, { params })
					.then((res) => {
						const { type, body } = res.data;
						if (type === "success") this.phones = body;
					})
					.finally(() => (this.loading.phones = false));
			}
		},
		reset() {
			this.form = {
				id: null,
				form_id: null
			};
			this.$refs.importContactsForm.reset();
			this.$emit("close");
		},
		handleImportContacts(closeHandler) {
			this.$refs.importContactsForm.validate().then((success) => {
				if (this.form.types.length == 0)
					return this.$refs.typeProvider.applyResult({
						errors: ["Selecione pelo menos um tipo de importação (contatos ou chats)"],
						valid: false,
						failedRules: {}
					});

				if (!success) return;

				this.loading.submit = true;

				api.post(`/phones/${this.phone._id}/import`, this.form)
					.then((response) => {
						const { type, message } = response.data;

						if (type === "success") {
							this.toast(message, "success");
							this.$emit("update");
							return closeHandler();
						}

						this.toast(message);
					})
					.catch((error) => {
						if (error.response) {
							return this.toast(error.response.data.message);
						}

						this.toast(
							`Não foi possível configurar a importação do número, tente novamente em alguns minutos.`
						);
					})
					.finally(() => (this.loading.submit = false));
			});
		}
	}
};
</script>
